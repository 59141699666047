import { useState } from "react";

const PlayerCardSkeleton = () => {
  return (
    <div className="col">
      <div
        className="card"
        style={{ background: "#E1ECE4", overflow: "hidden", height: "250px" }}
      >
        <div className="skeleton-img"></div>
        <div className="player_details d-flex flex-column text-start w-70 p-2">
          <span className="player_num p-1 fw-bold mb-3 skeleton-text"></span>
          <span className="player_name fs-6 fw-bold skeleton-text"></span>
          <span className="player_name fs-6 fw-bold skeleton-text"></span>
          <small className="player_postion text-secondary skeleton-text"></small>
        </div>
      </div>
    </div>
  );
};

const PlayerCard = ({ player, onEdit, onDelete, isAdmin }) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  const userImage = `${player.image.replace("/", "")}`;

  const splitIimage = userImage.split(":");

  const imagePlayer = `https:/${splitIimage[1]}`;

  console.log("Image", imagePlayer);
  console.log("isAdmin", isAdmin);

  return (
    <div key={player.id} className="col">
      <div
        className="card"
        style={{ background: "#E1ECE4", overflow: "hidden" }}
      >
        <img
          src={imagePlayer}
          className="card-img-top"
          alt="..."
          onLoad={handleImageLoad}
          style={{ display: imageLoaded ? "block" : "none" }}
        />
        {!imageLoaded && <PlayerCardSkeleton />}
        <div className="player_details d-flex flex-column text-start w-70 p-2">
          <span className="player_num p-1 fw-bold mb-3">{player.number}</span>
          <span className="player_name fs-6 fw-bold">{player.last_name}</span>
          <span className="player_name fs-6 fw-bold">{player.first_name}</span>
          <small className="player_postion text-secondary">
            {player.position}
          </small>
        </div>
        {isAdmin ? (
          <button
            className="position-absolute top-0 end-0 btn btn-outline-transparent pt-2 pe-2"
            type="button"
            style={{ zIndex: "3" }}
            onClick={onEdit}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </button>
        ) : null}
        {isAdmin ? (
          <button
            className="position-absolute bottom-0 end-0 btn btn-outline-transparent text-danger pt-2 pe-2"
            type="button"
            style={{ zIndex: "3" }}
            onClick={onDelete}
          >
            <i className="fa-solid fa-trash-can"></i>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default PlayerCard;
