/* eslint-disable no-unused-vars */
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useRef, useState, useEffect } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handlePayment, updatePayment } from "../../redux/slices/paymentSlice";
import PaymentService from "../../services/PaymentService";
import { formatPrice } from "../../utils/utils";
import CircularLoader from "../loader/CircularLoader";
import { PaymentMethod } from "../PaymentMethod";
import PriceDetail from "../PriceDetail";
import "./../styles/PaymentForm.css";
import { PAYMENT_STATUS } from "../../utils/constants";

const PaymentForm = ({
  onGoBack,
  amount,
  title,
  stripe_fee,
  dataForPayment,
  onPaymentSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const mobileFormRef = useRef();
  const cardFormRef = useRef();
  const operatorRef = useRef();
  const phoneRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [onPayment, setOnPayment] = useState(false);
  const [onWaitingMobilePayment, setOnWaitingMobilePayment] = useState(false);
  const [onStripePayment, setOnStripePayment] = useState(false);
  var paymentStatusCheckerId;
  var paymentStatusCheckerEllapsed = 0;

  const [total, setTotal] = useState({ mobile: 0, cc: 0 });

  const handleOpenForm = (e) => {
    if (e.target.value === "mobile") {
      mobileFormRef.current.classList.add("open");
      cardFormRef.current.classList.remove("open");
    } else {
      cardFormRef.current.classList.add("open");
      mobileFormRef.current.classList.remove("open");
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const startCheckingPaymentStatus = async (id_payment) => {
    let paymentStatusCheckerEllapsed = 0;
    const maxWaitTime = 1000 * 120;
    const checkInterval = 4000;

    const INITIALIZE = "Progress";
    let PROGRESS = "Progress";

    while (INITIALIZE === PROGRESS) {
      try {
        const { data } = await PaymentService.check(id_payment);
        PROGRESS = data ?? data.status;

        console.log("data", data);

        console.log("Status:", PROGRESS);

        if (PROGRESS === PAYMENT_STATUS.RECEIVED) {
          // Paiement réussi
          setOnPayment(false);
          setOnWaitingMobilePayment(false);
          handlePaymentSuccess();
          break;
        } else if (PROGRESS === PAYMENT_STATUS.REJECTED) {
          // Paiement rejeté
          setOnPayment(false);
          setOnWaitingMobilePayment(false);

          toast.warn(
            "Le paiement a été rejeté, veuillez réessayer après quelques minutes.",
            { autoClose: true }
          );
          break;
        }
      } catch (err) {
        console.error(
          "Erreur lors de la vérification du statut du paiement :",
          err
        );
        toast.error(
          "Une erreur s'est produite lors de la vérification du paiement."
        );
      }

      // Attendre avant de vérifier à nouveau
      await delay(checkInterval);
      paymentStatusCheckerEllapsed += checkInterval;
    }

    if (paymentStatusCheckerEllapsed >= maxWaitTime) {
      setOnPayment(false);
      setOnWaitingMobilePayment(false);
      toast.warn("La transaction a été annulée après un délai d'attente.");
    }
  };
  const handleMobilePayment = (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneRef.current.value, "CM")) {
      return toast.error("Veuillez entrez un numéro de téléphone valide.");
    }

    const data = {
      payment_method: operatorRef.current.value,
      ...dataForPayment,
      phone: phoneRef.current.value,
    };

    setOnPayment(true);
    dispatch(handlePayment(data))
      .unwrap()
      .then((data) => {
        if (data.errors) {
          toast.error(data.errors.join("<br/>"));
          setOnPayment(false);
        } else {
          setOnPayment(false);
          if (dataForPayment.payment_type === "print") {
            toast.info("Votre paiement est en cours de vérification.", {
              autoClose: false,
            });
          } else if (dataForPayment.payment_type === "command") {
            toast.info("Votre paiement est en cours de vérification.", {
              autoClose: false,
            });
          } else {
            setOnWaitingMobilePayment(true);
            toast.info("Veuillez valider le paiement sur votre mobile svp.", {
              autoClose: true,
            });

            if (data.data.payment.id !== undefined) {
              startCheckingPaymentStatus(data.data.payment.id);
              handlePaymentSuccess(data);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);

        console.log(err);

        toast.error(
          "Une erreur s'est produite durant le paiement, veuillez vérifier vos informations, et réessayer svp."
        );
        setOnPayment(false);
      });
  };

  const handleStripePayment = async (e, stripe, elements) => {
    e.preventDefault();
    setOnStripePayment(true);
    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);
    if (error) {
      toast.error("Error: " + error.message);
    } else {
      // ... SEND to your API server to process payment intent
      const data = {
        payment_method: "cc",
        stripe_data: token,
        ...dataForPayment,
        phone: phoneRef.current.value,
      };

      dispatch(handlePayment(data))
        .unwrap()
        .then((data) => {
          if (data.errors) {
            toast.error(data.errors.join("<br/>"));
            setOnStripePayment(false);
          } else {
            handlePaymentSuccess(data);
          }
        })
        .catch((err) => {
          toast.error(
            "Une erreur s'est produite durant le paiement, veuillez vérifier vos informations, votre solde et réessayer svp."
          );
          setOnStripePayment(false);
        });
    }
  };

  const handlePaymentSuccess = (data) => {
    dispatch(updatePayment(data)).then(() => {
      localStorage.setItem("cardIdToPreview", data.data.subscription.id);
      setTimeout(() => {
        navigate("/payment");
      }, 3000);
      return;
    });
  };

  const [paymentMethod, setPaymentMethod] = useState({
    value: "om",
    name: "Orange Money",
  });

  const handleSelectPaymentMethod = (method) => {
    setPaymentMethod(method);
    if (["om", "momo"].indexOf(method.value) >= 0) {
      operatorRef.current.value = method.value;
      handleOpenForm({ target: { value: "mobile" } });
    } else {
      handleOpenForm({ target: { value: "cc" } });
    }
  };

  useEffect(() => {
    setTotal((value) => {
      return {
        ...value,
        mobile: parseFloat(amount),
      };
    });
    setTotal((value) => {
      return {
        ...value,
        cc: (stripe_fee * parseFloat(amount)) / 100 + parseFloat(amount),
      };
    });
  }, [amount, dataForPayment, stripe_fee]);

  return (
    <>
      {!onPayment && !onWaitingMobilePayment ? (
        <div className="paymentForm w-100 d-flex flex-column">
          <div className="col-12 col-sm-10 col-md-8 col-xl-7 mx-auto px-4">
            <h4 className="text-dark fw-bold text-uppercase">
              méthode de paiement
            </h4>
            <PaymentMethod onSelect={handleSelectPaymentMethod} />
            <form
              onSubmit={handleMobilePayment}
              ref={mobileFormRef}
              className="mobileForm"
            >
              <div className="form-check p-0 m-0 py-1">
                <input
                  value={"mobile"}
                  onClick={handleOpenForm}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label className="form-check-label text-left">
                  Paiement par {paymentMethod.name} ({formatPrice(total.mobile)}
                  Paiement par {paymentMethod.name} ({formatPrice(total.mobile)}
                  )
                </label>
              </div>
              <PriceDetail
                key={"PriceDetail-0"}
                price={parseInt(amount)}
                fee={2.7}
              />
              <div className="form-floating py-1 visually-hidden">
                <select
                  ref={operatorRef}
                  name="operator"
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                >
                  <option value="om">Orange</option>
                  <option value="momo">MTN</option>
                </select>
                <label htmlfor="floatingSelect">
                  Sélectionner l'opérateur mobile
                </label>
              </div>
              <div className="form-floating mb-3 pt-2">
                <input
                  required
                  ref={phoneRef}
                  name="phone"
                  type="tel"
                  className="form-control"
                  id="floatingInput"
                  placeholder="numéro de téléphone"
                />
                <label htmlfor="floatingInput">Numéro de téléphone</label>
              </div>
              <button type="submit" className="btn w-100 btn-primary my-2">
                Payer
              </button>
            </form>

            <form
              onSubmit={(event) => handleStripePayment(event, stripe, elements)}
              ref={cardFormRef}
              className="mobileForm"
            >
              <div className="form-check p-0 m-0 py-1">
                <input
                  value={"cc"}
                  onClick={handleOpenForm}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label
                  className="form-check-label text-left"
                  htmlfor="flexRadioDefault1"
                >
                  Paiement par {paymentMethod.name} ({formatPrice(total.cc)})
                </label>
              </div>
              {total.mobile > 0 && (
                <PriceDetail
                  key={"PriceDetail-1"}
                  price={parseInt(amount)}
                  fee={stripe_fee}
                />
              )}
              <div className="CardElement my-4 py-4 form-control">
                <CardElement />
              </div>
              <button
                disabled={!stripe || onStripePayment}
                type="submit"
                className="btn w-100 btn-primary my-2"
              >
                {!onStripePayment ? "Payer" : <CircularLoader />}
              </button>
            </form>
          </div>
        </div>
      ) : (
        <CircularLoader
          content={
            !onWaitingMobilePayment ? (
              <small className="text-muted">paiement en cours</small>
            ) : (
              "Veuillez confirmer le paiement sur votre téléphone mobile SVP."
            )
          }
        />
      )}
    </>
  );
};

const StripePaymentForm = (props) => <PaymentForm {...props} />;

export default StripePaymentForm;
