import React from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// impo rt { Provider } from 'react-redux';
// import store from './redux/store';
import axios from 'axios';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import LoginPage from './pages/LoginPage'
import { Provider } from 'react-redux';
import { store } from './redux/store';
import RegisterPage from "./pages/RegisterPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import OtpPage from "./pages/OtpPage";
// import DashboardPage from "./pages/DashboardPage";
import CardPage from "./pages/CardPage";
import PlayerPage from "./pages/PlayerPage";
import PaymentPage from "./pages/PaymentPage";
import PlaintePage from "./pages/PlaintePage";
import ProfilPage from "./pages/ProfilPage";
import DonationPage from "./pages/DonationPage";
import ShopPage from "./pages/ShopPage";
import HomePage from "./pages/HomePage";
// import MatchPage from "./pages/GamePage";
import GamePage from "./pages/GamePage";
import VotePage from "./pages/VotePage";
import MemberPage from "./pages/MemberPage";
import IncomePage from "./pages/IncomePage";
import NotFoundPage from "./pages/NotFoundPage";
import RecoverPasswordPage from "./pages/RecoverPasswordPage";
import CandidatePage from "./pages/CandidatePage";
import ParticipationsPage from "./pages/ParticipationsPage";
import AcademyPage from "./pages/AcademyPage";
import DonPage from "./pages/DonPage";


const root = ReactDOM.createRoot(document.getElementById('root'));

//router
const router = createBrowserRouter([
    {
        path: "/login",
        element: (<LoginPage />),
    },
    {
        path: "/register",
        element: (<RegisterPage />),
    },
    {
        path: "/donation",
        element: (<DonationPage />),
    },

    {
        path: "/don",
        element: (<DonPage />),
    },

    {
        path: "/verify-otp",
        element: (<OtpPage />),
    },
    {
        path: "/forgot-password",
        element: (<ForgotPasswordPage />),
    },
    {
        path: "/recover-password",
        element: (<RecoverPasswordPage />),
    },
    /* {
        path: "/test",
        element: (<TestPage />),
    }, */
    {
        path: "/",
        element: (<HomePage />),
    },
    {
        path: "/dashboard",
        element: (<IncomePage />),
    },
    {
        path: "/income",
        element: (<IncomePage />),
    },
    {
        path: "/card",
        element: (<CardPage />),
    },
    {
        path: "/payment",
        element: (<PaymentPage />),
    },
    {
        path: "/game",
        element: (<GamePage />),
    },
    {
        path: "/profil",
        element: (<ProfilPage />),
    },
    {
        path: "/member",
        element: (<MemberPage />),
    },
    {
        path: "/candidates",
        element: (<CandidatePage />),
    },
    {
        path: "/participations",
        element: (<ParticipationsPage />),
    },
    {
        path: "/player",
        element: (<PlayerPage />),
    },
    {
        path: "/vote",
        element: (<VotePage />),
    },
    {
        path: "/complaint",
        element: (<PlaintePage />)
    },
    {
        path: "/shop",
        element: (<ShopPage />),
    },
    {
        path: "/academy",
        element: (<AcademyPage />),
    },
    {
        path: "*",
        element: (<NotFoundPage />),
    },
]);


// default axios configs
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;
axios.defaults.withCredentials = true;


root.render(
    <Provider store={store}>
        <RouterProvider router={router}>
            <App />
        </RouterProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
//ServiceWorker.register()