import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
// import CircleGroup from '../components/CircleGroup';
// import Navbar from '../components/navigation/Navbar';
import FloatButton from "../components/floatButton/FloatButton";
import useAuthToken from "../hook/useAuthToken";
// import useLanguage from "../hook/useLanguage";
import { getCurrentUser } from "../redux/slices/userSlice";
import Box from "./Box";
import CircularLoader from "./loader/CircularLoader";
import SideBar from "./navigation/SideBar";
import TopBar from "./navigation/TopBar";
import "./styles/AdminContainer.css";

const AdminContainer = ({ ...props }) => {
  // const [language, t, handleChangeLanguage] = useLanguage();
  const userData = useSelector((state) => state.user);
  const authToken = useAuthToken();
  const dispatch = useDispatch();
  // const [isAuth, setAuth] = useState(false);
  const sideBarRef = useRef();
  const contentRef = useRef();
  const navigate = useNavigate();

  const isAdmin = 1;

  const handleToggleSideBar = (e) => {
    if (sideBarRef.current.classList.contains("open")) {
      sideBarRef.current.classList.remove("open");
    } else {
      sideBarRef.current.classList.add("open");
    }
  };

  const handleContentClick = (e) => {
    if (window.innerWidth <= 1200 && e.clientX > 250) {
      sideBarRef.current.classList.remove("open");
    }
  };

  useEffect(() => {
    if (!authToken) {
      navigate("/register");
    }
    if (!userData) {
      dispatch(getCurrentUser())
        .unwrap()
        .then((data) => {
          if (!(data && parseInt(data.active) === 1)) {
            navigate("/register");
          }
        })
        .catch((err) => navigate("/register"));
    }
    if (window.outerWidth <= 1200 && sideBarRef.current) {
      sideBarRef.current.classList.remove("open");
    }
  }, [authToken, dispatch, navigate, userData]);

  return (
    <div className="AdminContainer" onClick={handleContentClick}>
      {!userData && <Box content={<CircularLoader />} />}
      {userData && (
        <div className="AdminContainer__child d-flex">
          <div
            ref={sideBarRef}
            className="AdminContainer__sideBar open shadow-xl"
          >
            <SideBar admin={userData.admin === 1} />
          </div>
          <div
            ref={contentRef}
            className="AdminContainer__content p-2 p-md-3 p-xl-4 w-100"
          >
            <TopBar onToggle={handleToggleSideBar} user={userData} />
            <div className="BottomContent mt-5">{props.children}</div>
          </div>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />

      <FloatButton />
    </div>
  );
};

export default AdminContainer;
