/* eslint-disable no-unused-vars */
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../components/AdminContainer";
import Box from "../components/Box";
import DownloadViewer from "../components/DownloadViewer";
import ProfilForm from "../components/form/ProfilForm";
import ImageViewer from "../components/ImageViewer";
import CircularLoader from "../components/loader/CircularLoader";
import useLanguage from "../hook/useLanguage";
import { getCurrentUser } from "../redux/slices/userSlice";
import "./../styles/ProfilPage.css";

const ProfilPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const userData = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!userData) dispatch(getCurrentUser());
  }, [dispatch, userData]);

  return (
    <AdminContainer>
      {userData ? (
        <div className="">
          <h4 className="fw-bold text-light text-uppercase">profil</h4>
          <ProfilForm user={userData} />
        </div>
      ) : (
        <Box content={<CircularLoader />} />
      )}
    </AdminContainer>
  );
};

export default ProfilPage;
