import React, { useState } from 'react'
import '../../styles/mobileMenu.css'
import { NavLink } from 'react-router-dom'
import useLanguage from '../../hook/useLanguage'
import { useDispatch } from 'react-redux'
import { logoutCurrentUser } from "../../redux/slices/userSlice";


const MobileMenu = () => {
    const dispatch = useDispatch() ;
    const userToken = localStorage.getItem('authToken');
    
    const  [language , t , handleChangeLanguage]  = useLanguage();
    const [isMobileMenuOpen , setIsMobileMenuOpen] = useState(false);
    // const userData = useSelector((state) => state.user);


    const handleLogout = () => {
        localStorage.clear();
        dispatch(logoutCurrentUser()).then(() => {
          setTimeout(() => {
            window.location.href = "/";
          }, 1500);
        });
    };



  return (
    <div>

        <div 
            className="toggleBut d-flex flex-column justify-content-around align-items-center" 
            style={{ width: "24px" ,  height: '24px' , zIndex:"20"}}
            onClick={()=> {setIsMobileMenuOpen(!isMobileMenuOpen)}}
        >
            <div className={`toggleBut__Ele w-100 ${isMobileMenuOpen ? 'but__top' : ''}`} style={{ height: '4px' }}></div>
            <div className={`toggleBut__Ele w-100 ${isMobileMenuOpen ? 'but__middle' : ''}`} style={{ height: '4px' }}></div>
            <div className={`toggleBut__Ele w-100 ${isMobileMenuOpen ? 'but__bottom' : ''}`} style={{ height: '4px' }}></div>
        </div>

        {isMobileMenuOpen && (
            <div className='cover'>

                {!userToken ? (
                    <>
                        <NavLink to="/login" className="mobile__link" >{t("Login")}</NavLink>

                        <NavLink to="/register" className="mobile__link">{t("Register")}</NavLink>
                    </>
                ):(
                    <>
                        <NavLink to="/card" className="mobile__link">{t("Dashboard")}</NavLink>
                        <div className='mobile__link' onClick={()=>{handleLogout()}} style={{cursor: "pointer"}}>{t('Logout')}</div>
                    </>
                )}
                <NavLink to="/don" className="mobile__link">{t("Donation")}</NavLink>
                <div className="language-change d-flex justify-content-between gap-1">
                    <span
                        onClick={() => handleChangeLanguage("fr")}
                        className={
                        "language-change__item language-change__fr" +
                        (language === "fr" ? " language-change__item--active" : "")
                        }
                    >
                        FR
                    </span>
                    <span>|</span>
                    <span
                        onClick={() => handleChangeLanguage("en")}
                        className={
                        "language-change__item language-change__en" +
                        (language === "en" ? " language-change__item--active" : "")
                        }
                    >
                        EN
                    </span>
                </div>
            </div>
        )}

    </div>
   
    
  )
}

export default MobileMenu
