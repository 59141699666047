import AppContainer from "../components/AppContainer";
import RecoverPasswordForm from "../components/form/RecoverPasswordForm";
import "./../styles/LoginPage.css";

const RecoverPasswordPage = () => {
  return (
    <AppContainer>
      <RecoverPasswordForm />
    </AppContainer>
  );
};

export default RecoverPasswordPage;
