const baseUri = process.env.REACT_APP_RECRUIT;
// const baseUri = "http://localhost:5500/api";

const getAll = () => {
  return fetch(`${baseUri}/candidates`)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      return data;
    });
};

const getCandidatesPayments = async () => {
  const response = await fetch(`${baseUri}/payments`);
  const data = await response.json();
  return data;
};

const CandidateService = {
  getAll,
  getCandidatesPayments,
};

export default CandidateService;
