import axios from "axios"
import { formatDate } from "../utils/utils";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = (interval = [new Date(), new Date()]) => {

    let url = `admin/users`
    if (interval) {
        const startDate = formatDate(interval[0])
        const endDate = formatDate(interval[1])
        url = `admin/users?startDate=${startDate}&endDate=${endDate}`
    }
    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const active = ({ id, active }) => {
    let url = `admin/users/active/${id}/${active}`

    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const admin = ({ id, admin }) => {
    let url = `admin/users/admin/${id}/${admin}`

    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const superAdmin = ({ id, super_admin }) => {
    let url = `admin/users/super-admin/${id}/${super_admin}`

    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}

const MemberService = {
    getAll,
    active,
    admin,
    superAdmin
}

export default MemberService;