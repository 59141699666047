/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../components/AdminContainer";
import Box from "../components/Box";
import CardData from "../components/card/CardData";
import ImageViewer from "../components/ImageViewer";
import CircularLoader from "../components/loader/CircularLoader";
import { Modal } from "../components/Modal";
import useLanguage from "../hook/useLanguage";
import { getCandidates } from "../redux/slices/candidateSlice";
import CandidatePaymentService from "../services/CandidatePaymentService";

import { nFormatter, parseReadableDate } from "../utils/utils";
import "./../styles/PaymentPage.css";
import "./../styles/MemberPage.css";

const CandidatePage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const candidateData = useSelector((state) => state.candidate);
  const [paymentData, setPaymentData] = useState(null);
  const userData = useSelector((state) => state.user);

  const [imageViewer, setImageViewer] = useState(null);
  const [seasonViewer, setSeasonViewer] = useState(null);
  const [
    subscriptionToDownload,
    // setSubscriptionToDownload
  ] = useState(null);
  const [
    // preview,
    setPreview,
  ] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCandidates({ reset: false, interval: null }));

    if (!paymentData) {
      CandidatePaymentService.getAll().then((payments) => {
        setPaymentData(payments);
      });
    }
  }, [dispatch, paymentData]);

  const candidateDataColumns = [
    {
      name: "Profil",
      selector: (row) => {
        if (row.image) {
          return (
            <img
              onClick={() => setImageViewer(row.image.url)}
              src={row.image.url}
              className="w-50 p-2"
              style={{ borderRadius: "50%" }}
              alt=""
            />
          );
        }
        return (
          <small className="text-muted small" style={{ userSelect: "none" }}>
            non renseignée
          </small>
        );
      },
    },
    {
      name: "Date ins.",
      selector: (row) => {
        return parseReadableDate(row.createdAt);
      },
      sortable: true,
      sortFunction: (a, b) => {
        let aDate = new Date(a.createdAt);
        let bDate = new Date(b.createdAt);
        return aDate - bDate;
      },
    },
    {
      name: "Date nais.",
      selector: (row) => {
        return parseReadableDate(row.dob);
      },
      sortable: true,
      sortFunction: (a, b) => {
        let aDate = new Date(a.dob);
        let bDate = new Date(b.dob);
        return aDate - bDate;
      },
    },
    {
      name: "Nom(s)",
      selector: (row) => {
        return row.lastname;
      },
      sortable: true,
    },
    {
      name: "Prénom(s)",
      selector: (row) => {
        return row.firstname;
      },
      sortable: true,
    },
    {
      name: "Poste",
      selector: (row) => {
        return row.position;
      },
      sortable: true,
    },
    {
      name: "Pied fort",
      selector: (row) => {
        return row.strongFoot;
      },
      sortable: true,
    },
    {
      name: "Taille",
      selector: (row) => {
        return row.height + " m";
      },
      sortable: true,
    },
    {
      name: "Poids",
      selector: (row) => {
        return row.weight + " kg";
      },
      sortable: true,
    },
    {
      name: "Numéro de téléphone",
      selector: (row) => {
        return row.phone;
      },
      sortable: true,
    },
    {
      name: "Adresse mail",
      selector: (row) => {
        return row.email;
      },
      sortable: true,
    },
    {
      name: "Ville",
      selector: (row) => {
        return row.city;
      },
    },
    {
      name: "Adresse",
      selector: (row) => {
        return row.address;
      },
      sortable: true,
    },
  ];

  const [confirm, setConfirm] = useState(null);
  const [q, setQ] = useState(null);

  const customTextFilter = (data, search) => {
    if (search === null) {
      return data;
    }
    search = search.toLowerCase();
    return data.filter((val, key) => {
      return (
        val.firstname.toLowerCase().includes(search) ||
        val.lastname.toLowerCase().includes(search)
      );
    });
  };

  const applyActionRestrictionsColumns = (data, user) => {
    const restrictedColumns = ["Actif", "Admin", "Super Admin"];
    const superAdmin = parseInt(user.super_admin) === 1;
    if (!superAdmin) {
      return data.filter((col) => {
        return restrictedColumns.indexOf(col.name) === -1;
      });
    }
    return data;
  };

  const getEarnings = useMemo(
    () => (paymentData) => {
      let total = 0;
      paymentData.forEach((payment) => {
        if (payment.status === "SUCCEEDED") {
          total += Number(payment.amount);
        }
      });
      return total;
    },
    []
  );

  const earnings = paymentData
    ? nFormatter(getEarnings(paymentData), 2)
    : "...";

  return (
    <AdminContainer>
      {candidateData !== null && userData !== null ? (
        <div className="">
          <h4 className="fw-bold text-light text-uppercase">candidats</h4>
          <div className="CardDataContainer my-3 d-flex gap-3 justify-content-between p-0 m-0">
            <CardData
              value={`${candidateData.length}+`}
              label={"Candidats"}
              icon={<i className="fa-solid fa-user fa-5x text-light"></i>}
            />
            <CardData
              value={`${earnings}`}
              label={
                <span>
                  gagne chez <br />
                  les candidats
                </span>
              }
              icon={
                <i className="fa-solid fa-sack-dollar fa-5x text-light"></i>
              }
            />
          </div>
          <div className="d-flex justify-content-between w-100 mb-2 align-items-center">
            <h5 className="fw-bold text-light text-uppercase">
              listes des candidats
            </h5>
            {/* <span className="">{q && q.length > 0 && "Résultat pour :   '" + q + "'"}</span> */}
            <input
              maxLength={10}
              placeholder="Rechercher un candidat"
              onChange={(e) => setQ(e.target.value)}
              className="w-50 searchBar bg-transparent form-control mr-sm-2"
              type="search"
              aria-label="Search"
            ></input>
          </div>
          <DataTable
            className="PaymentHistory"
            columns={applyActionRestrictionsColumns(
              candidateDataColumns,
              userData
            )}
            data={customTextFilter(candidateData, q)}
            striped
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          />
        </div>
      ) : (
        <Box content={<CircularLoader />} />
      )}

      {imageViewer && (
        <ImageViewer
          image={imageViewer}
          season={seasonViewer}
          onClose={() => {
            setImageViewer(null);
            setSeasonViewer(null);
            setPreview(null);
          }}
          open={subscriptionToDownload !== null}
        />
      )}

      {confirm && (
        <Modal
          onClose={(e) => {
            setConfirm(null);
          }}
          open={confirm ?? false}
          title={
            <div className="text-uppercase" style={{ color: "#e89f29" }}>
              <i className="fas fa-exclamation-circle fa-1x"></i>{" "}
              {confirm.title}
            </div>
          }
        >
          {!confirm.onConfirm && (
            <div className="d-flex flex-column justify-content-between p-3">
              <div>{confirm.message}</div>
              <div className="d-flex justify-content-end w-100 mt-3">
                <button
                  style={{ background: "#e89f29 !important" }}
                  className="downloadBtn warning text-uppercase"
                  onClick={(e) => confirm.callback(e)}
                >
                  Confirmer
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </AdminContainer>
  );
};

export default CandidatePage;
