import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CandidateService from "../../services/CandidateService.js";
axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "authToken"
)}`;

const initialState = null;

export const getCandidates = createAsyncThunk(
  "candidate/all",
  async ({ reset }) => {
    if (reset) {
      return initialState;
    }
    const res = await CandidateService.getAll();
    return res;
  }
);

const candidateSlice = createSlice({
  name: "candidate",
  initialState,
  extraReducers: {
    [getCandidates.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

const { reducer } = candidateSlice;
export default reducer;
