import { formatPrice } from "../../utils/utils";
// import NoVote from "../vote/NoVote";
import "../styles/CardCarousel.css";

const CardItem = ({ card, isActive }) => {
  return (
    <div className={"card-item" + (isActive ? " card-item-middle shadow" : "")}>
      <div className="d-flex justify-content-center align-items-center">
        <div
          key={Math.random()}
          className={
            isActive
              ? "activeCardContent w-100 d-flex align-items-center justify-content-center"
              : "d-none"
          }
        >
          <div className="tooltiptext d-flex flex-column">
            <h6 className="fw-bold">AVANTAGES</h6>
            <div className="cardPlus__contain d-flex p-1 flex-column gap-1">
              {card.description.map((desc) => {
                return (
                  <div className="cardPlus">
                    <div className="cardPlus__content">{desc}</div>
                  </div>
                );
              })}
            </div>
            {/* <NoVote
                        label="bientôt disponible"
                        icon={<i className="commingSoon fa-solid fa-gift fa-2x"></i>}
                    /> */}
          </div>
        </div>
        <img
          alt={"carte " + card.type}
          className="img-fluid"
          src={card.image.replace("cards-type", "cards-models")}
        />
      </div>
    </div>
  );
};

const CardCarousel = ({
  cardItems,
  activeCardIndex,
  onPrev,
  onNext,
  onSelect,
}) => {
  const activeCard = cardItems[activeCardIndex];

  return (
    <div style={{ position: "relative" }} className="CardCarousel mt-5 mt-md-3">
      <div className="CardCarouselContainer d-flex align-items-center gap-3">
        {cardItems.map((card, index) => (
          <CardItem
            key={index}
            card={card}
            isActive={index === activeCardIndex}
          />
        ))}
      </div>
      <div className="CardCarouselController flex-column mt-3 d-flex justify-content-center align-items-center">
        <h6 className="text-white mt-5 mt-md-2">
          {formatPrice(activeCard?.card_price)}
        </h6>
        <button
          className="btn btn-primary fw-bold text-white"
          onClick={() => onSelect(activeCard)}
        >
          Acheter <i className="fa fa-shopping-cart ms-1"></i>
        </button>
        <div className="CardCarouselControllerActions d-flex gap-2 mt-2">
          <button onClick={onPrev}>
            <i className="fa fa-arrow-left"></i>
          </button>
          <button onClick={onNext}>
            <i className="fa fa-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export { CardCarousel, CardItem };
