import './../styles/PaymentMethod.css';

export const PaymentMethod = ({ onSelect, className }) => {

    const handleSelect = (e) => {
        let value = e.target.value;
        if (value === 'om') value = { value, name: "Orange Money" }
        if (value === 'momo') value = { value, name: "MTN Money" }
        if (value === 'visa') value = { value, name: "VISA" }
        if (value === 'mastercard') value = { value, name: "Mastercard" }
        onSelect(value)
    }

    return <div className={"PaymentMethod " + className}>
        <form className='d-flex gap-2'>
            <div className='PaymentMethod__item d-flex justify-content-center align-items-center flex-column'>
                <label htmlhtmlfor='om'>
                    <img src='./../../images/om.jpg' alt='OM' />
                </label>
                <input onClick={handleSelect} value={'om'} className="form-check-input" type="radio" id="om" name='method' />
            </div>

            <div className='PaymentMethod__item d-flex justify-content-center align-items-center flex-column'>
                <label htmlhtmlfor='momo'>
                    <img src='./../../images/momo.webp' alt='MOMO' />
                </label>
                <input onClick={handleSelect} value={'momo'} className="form-check-input" type="radio" id="momo" name='method' />
            </div>

            <div className='PaymentMethod__item d-flex justify-content-center align-items-center flex-column'>
                <label htmlhtmlfor='visa'>
                    <img src='./../../images/visa.jpg' alt='VISA' />
                </label>
                <input onClick={handleSelect} value={'visa'} className="form-check-input" type="radio" id="visa" name='method' />
            </div>

            <div className='PaymentMethod__item d-flex justify-content-center align-items-center flex-column'>
                <label htmlhtmlfor='mastercard'>
                    <img src='./../../images/mastercard.webp' alt='MasterCard' />
                </label>
                <input onClick={handleSelect} value={'mastercard'} className="form-check-input" type="radio" id="mastercard" name='method' />
            </div>

        </form>
    </div>

}

