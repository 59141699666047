/* eslint-disable no-loop-func */
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { getModelById, parseReadableDate } from "../../utils/utils";

export const VoteRanking = ({ vote, players, games, onBack }) => {
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        padding: "0.5rem 0.5rem",
        backgroundColor: "var(--bs-table-bg)",
        borderBottomWidth: "1px",
        boxShadow: "inset 0 0 0 9999px var(--bs-table-accent-bg)",
        fontSize: "1.1em",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const getPlayerVoteResult = (voteData, playerId) => {
    let voting = 0;
    voteData.votes.forEach((vote) => {
      if (vote[1] === playerId) {
        voting++;
      }
    });
    return voting;
  };

  //   const getBestVote = (voteData) => {
  //     let bestVoting = {
  //       voting: 0,
  //       player: null,
  //     };
  //     voteData.players_id.map((id) => {
  //       const voting = getPlayerVoteResult(voteData, id);
  //       if (bestVoting.voting < voting) {
  //         bestVoting = { voting: voting, player: getModelById(players, id) };
  //       }
  //     });
  //     return bestVoting;
  //   };

  const columns = [
    {
      name: "Rang",
      selector: (row, index) => {
        return index + 1;
      },
      sortable: true,
    },
    {
      name: "Image",
      selector: (row) => {
        if (row.image) {
          return <img src={row.image} className="w-50 p-2" alt="" />;
        }
        return (
          <small className="text-muted small" style={{ userSelect: "none" }}>
            non renseignée
          </small>
        );
      },
    },
    {
      name: `Nombre De Votes (${vote.votes.length})`,
      selector: (row) => {
        return row.voting;
      },
      sortable: true,
    },
    {
      name: "Nom",
      selector: (row) => {
        return (
          <span className="" style={{ userSelect: "none" }}>
            {row.last_name}
          </span>
        );
      },
    },
    {
      name: "Prénom",
      selector: (row) => {
        return (
          <span className="" style={{ userSelect: "none" }}>
            {row.first_name}
          </span>
        );
      },
    },
    {
      name: "Poste",
      selector: (row) => {
        return (
          <span className="" style={{ userSelect: "none" }}>
            {row.position}
          </span>
        );
      },
      sortable: true,
    },
    // {
    //     name: 'Joueurs',
    //     selector: (row) => {
    //         return <span>{row.players_id.length}</span>
    //     },
    //     sortable: true,
    // },
  ];

  const [rankings, setRankings] = useState(null);
  const [game, setGame] = useState(null);

  const getMaxResult = (result) => {
    var best = { player: null, voting: 0 };
    result.forEach((r) => {
      if (r.voting >= best.voting) {
        best = r;
      }
    });

    return best;
  };

  useEffect(() => {
    const orderResultByVoting = (participants) => {
      const maxLenght = participants.length;
      var bestVoting = getMaxResult(participants);
      let output = [bestVoting];
      participants = participants.filter((p) => p.id !== bestVoting.id);
      while (output.length !== maxLenght) {
        bestVoting = getMaxResult(participants);
        if (bestVoting === null) {
          break;
        }
        output.push(bestVoting);
        participants = participants.filter((p) => p.id !== bestVoting.id);
      }
      return output;
    };

    if (vote.game_id) setGame(getModelById(games, vote.game_id));
    let participants = vote.players_id.map((id) => getModelById(players, id));

    participants = participants.map((participant) => {
      return {
        ...participant,
        voting: getPlayerVoteResult(vote, participant.id),
      };
    });
    // console.log(orderResultByVoting(participants));
    setRankings(orderResultByVoting(participants));
  }, [games, players, vote]);

  return (
    <>
      <div className="container-fluid mt-4">
        <h5 className="d-flex fw-bold text-light gap-2">
          <i
            onClick={onBack}
            className="backButton fa fa-arrow-left-long fa-1x"
          ></i>{" "}
          Modifier l'article {vote.name}
        </h5>
        <div className="d-flex justify-content-between w-100 mb-2">
          <div className="d-flex justify-content-between align-items-center">
            <div className="fw-bold text-uppercase me-2  text-light">
              {" "}
              {vote.name}
            </div>
            <div className="text-muted small">
              (Du {parseReadableDate(vote.interval[0])} Au{" "}
              {parseReadableDate(vote.interval[1])})
            </div>
            <div className="fw-bold text-uppercase ms-2  text-light">
              {game === null ? (
                "Meilleur Joueur"
              ) : (
                <div className="d-flex  text-light flex-row justify-content-between align-items-center gap-1">
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "10px",
                      }}
                      src={game.team1.logo}
                      alt={game.team1.name}
                    />
                    <span>{game.team1.name}</span>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div>VS</div>
                    <div
                      className="voteResult bg-black"
                      style={{ transform: "scale(.7)" }}
                    >
                      {game.score}
                    </div>
                  </div>
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <img
                      style={{
                        width: "20px",
                        height: "20px",
                        borderRadius: "10px",
                      }}
                      src={game.team2.logo}
                      alt={game.team2.name}
                    />
                    <span>{game.team2.name}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {rankings && (
          <DataTable
            className="PaymentHistory"
            columns={columns}
            data={rankings}
            striped
            pagination
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
            customStyles={customStyles}
          />
        )}
      </div>
    </>
  );
};
