// import { useState } from "react"

// /**
//  * initialValues should be the name of each input
//  *
//  * @param {*} initialValues
//  * @param {function} validator
//  * @returns [formValues, errors, handleChange, handleSubmit]
//  */
// const useForm = (initialValues, validator) => {
//     const [formValues, setFormValues] = useState(initialValues)
//     const [errors, setErrors] = useState({})

//     /**
//      * Handle form input change
//      *
//      * If you don't provide the value on handleChange callback
//      * Otherwise hook will try to select inputs via their name
//      *
//      * @param {*} event
//      * @param {*} value //formvalue key
//      */
//     const handleChange = (event, name = null, value = null) => {
//         const target = event?.target;
//         const targetName = target?.getAttribute('name');
//         if (name === null) {
//             setFormInputValue(targetName, target.value);
//         } else {
//             if (event === null) {
//                 setFormInputValue(name, value);
//             } else {
//                 setFormInputValue(name, target.value);
//             }
//         }
//     }

//     const setFormInputValue = (name, value) => {
//         setFormValues((function () {
//             let formValuesClone = { ...formValues };
//             formValuesClone[name] = value;
//             return formValuesClone;
//         })())
//     }

//     /**
//      * Handle form submit and apply validation rules
//      *
//      * @param {*} event
//      */
//     const handleSubmit = (event) => {
//         event.preventDefault();
//         event.stopPropagation();

//         const errorsClone = {};

//         setErrors(errorsClone);

//         const keys = Object.keys(formValues);

//         for (const key of keys) {
//             let validate = validator(key, formValues[key]);
//             if (validate !== null) {
//                 errorsClone[key] = validate;
//             }
//         }

//         setErrors(errorsClone)

//         return Object.keys(errorsClone).length === 0;
//     }

//     return [formValues, errors, setErrors, handleChange, handleSubmit];

// }

// export default useForm;

import { useState } from "react";

/**
 * initialValues should be the name of each input
 *
 * @param {*} initialValues
 * @param {function} validator
 * @returns [formValues, errors, setErrors, handleChange, handleFileChange, handleSubmit]
 */
const useForm = (initialValues, validator) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [errors, setErrors] = useState({});

  /**
   * Handle form input change
   *
   * If you don't provide the value on handleChange callback
   * Otherwise hook will try to select inputs via their name
   *
   * @param {*} event
   * @param {*} name // form value key
   * @param {*} value
   */
  const handleChange = (event, name = null, value = null) => {
    const target = event?.target;
    const targetName = target?.getAttribute("name");
    if (name === null) {
      setFormInputValue(targetName, target.value);
    } else {
      if (event === null) {
        setFormInputValue(name, value);
      } else {
        setFormInputValue(name, target.value);
      }
    }
  };

  /**
   * Handle file input change
   *
   * @param {*} event
   */
  const handleFileChange = (event) => {
    if (!event?.target?.files) return;

    const target = event.target;
    const name = target.getAttribute("name");
    const file = target.files[0];
    if (file) {
      setFormValues((prevValues) => ({
        ...prevValues,
        [name]: file,
      }));
    }
  };

  const setFormInputValue = (name, value) => {
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  /**
   * Handle form submit and apply validation rules
   *
   * @param {*} event
   */
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    console.log("Start Submit");

    const errorsClone = {};

    setErrors(errorsClone);

    const keys = Object.keys(formValues);

    // console.log("keys", keys);

    for (const key of keys) {
      let validate = validator(key, formValues[key]);
      if (validate !== null) {
        errorsClone[key] = validate;
      }
    }

    setErrors(errorsClone);
    //console.log("IsErrorr".Object.keys(errorsClone).length);

    return Object.keys(errorsClone).length === 0;
  };

  return [
    formValues,
    errors,
    setErrors,
    handleChange,
    handleFileChange,
    handleSubmit,
  ];
};

export default useForm;
