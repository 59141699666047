/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AdminContainer from "../components/AdminContainer";
import Box from "../components/Box";
import { Modal } from "react-bootstrap";
import CircularLoader from "../components/loader/CircularLoader";

import { getComplaints } from "../redux/slices/complaintSlice";
import { getMembers } from "../redux/slices/memberSlice";

import { getModelById, parseReadableDate } from "../utils/utils";

import "./../styles/PlaintePage.css";

const PlaintePage = () => {
  const complaints = useSelector((state) => state.complaint);
  const members = useSelector((state) => state.member);
  const dispatch = useDispatch();
  const [descriptionToShow, setDescriptionToShow] = useState(null);
  const [q, setQ] = useState(null);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        padding: "0.5rem 0.5rem",
        backgroundColor: "#ffffff21",
        borderBottomWidth: "1px",
        boxShadow: "inset 0 0 0 9999px var(--bs-table-accent-bg)",
        fontSize: "1.1em",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px",
        paddingRight: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Date d'envoi",
      selector: (row) => {
        return parseReadableDate(row.created_at);
      },
      sortable: true,
      sortFunction: (a, b) => {
        let aDate = new Date(a.created_at);
        let bDate = new Date(b.created_at);
        return aDate - bDate;
      },
    },
    {
      name: "Nom(s) et Prénom(s)",
      selector: (row) => {
        const user = getModelById(members, row.user_id);
        return (
          <span>
            {user.last_name} {user.first_name}
          </span>
        );
      },
      sortable: true,
    },
    {
      name: "Profil",
      selector: (row) => {
        const user = getModelById(members, row.user_id);
        if (user && user.image) {
          return (
            <img
              src={user.image}
              className="w-50 p-2"
              style={{ borderRadius: "50%" }}
              alt=""
            />
          );
        }
        return (
          <small className="text-muted small" style={{ memberselect: "none" }}>
            non renseignée
          </small>
        );
      },
    },
    {
      name: "Contact",
      selector: (row) => {
        const user = getModelById(members, row.user_id);
        if (user && user.phone) {
          return (
            <span className=" " style={{ memberselect: "none" }}>
              {user.phone} <br /> {user.email}
            </span>
          );
        }
        return (
          <small className="text-muted small" style={{ memberselect: "none" }}>
            non renseignée
          </small>
        );
      },
    },
    {
      name: "Contenu",
      selector: (row) => {
        return (
          <textarea
            title={row.description}
            className="border-none text-area-td"
            value={row.description}
            disabled
          ></textarea>
        );
      },
    },
    {
      name: "Actions",
      selector: (row) => {
        return (
          <div>
            <button
              onClick={(e) => handleShowComplaintDescription(e, row)}
              title={"afficher"}
              style={{ backgroundColor: "transparent", color: "#ffffff" }}
              className="btn bg-transparent flex-column d-flex align-items-center justify-content-around gap-2"
            >
              <i className="fa fa-eye mt-1" aria-hidden="true"></i> voir plus
            </button>
          </div>
        );
      },
      sortable: true,
    },
  ];

  const handleShowComplaintDescription = (e, complaint) => {
    setDescriptionToShow({
      object: complaint.object,
      description: complaint.description,
    });
  };

  const customTextFilter = (data, search) => {
    if (search === null) {
      return data;
    }
    search = search.toLowerCase();
    return data.filter((val, key) => {
      let member = getModelById(members, val.user_id);
      return (
        member.first_name.toLowerCase().includes(search) ||
        member.last_name.toLowerCase().includes(search)
      );
    });
  };

  useEffect(() => {
    !complaints && dispatch(getComplaints());
    !members && dispatch(getMembers({ reset: false, interval: null }));
  }, [complaints, dispatch, members]);

  return (
    <AdminContainer>
      <div className="container-fluid mt-4 w-100">
        {complaints !== null && members !== null ? (
          <>
            <div className="d-flex justify-content-between w-100 mb-2">
              <span className="text-light">
                {q && q.length > 0 && "Résultat pour :   '" + q + "'"}
              </span>
              <input
                maxLength={10}
                placeholder="Recherchez un membre"
                onChange={(e) => setQ(e.target.value)}
                className="w-50 searchBar form-control mr-sm-2 bg-transparent text-light"
                type="search"
                aria-label="Search"
              ></input>
            </div>
            <DataTable
              className="PaymentHistory"
              columns={columns}
              data={customTextFilter(complaints, q)}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              customStyles={customStyles}
            />
          </>
        ) : (
          <Box content={<CircularLoader />} />
        )}
      </div>

      {descriptionToShow && (
        <Modal
          show={descriptionToShow !== null}
          title={"Description"}
          onHide={() => {
            setDescriptionToShow(null);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>{descriptionToShow.object} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="p-2 m-2">{descriptionToShow.description}</div>
          </Modal.Body>
        </Modal>
      )}
    </AdminContainer>
  );
};

export default PlaintePage;
