/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../components/AdminContainer";
import DonationForm from "../components/form/DonationForm";
import CircularLoader from "../components/loader/CircularLoader";
import useLanguage from '../hook/useLanguage';
import { getDonations } from "../redux/slices/donationSlice";
import { getMembers } from "../redux/slices/memberSlice";
import {
  formatPrice,
  getModelById,
  nFormatter,
  parseReadableDate,
} from "../utils/utils";
import "./../styles/LoginPage.css";
import { getDonationPayment } from "../utils/utils";
import { getPayments } from "../redux/slices/paymentSlice";
import { PAYMENT_STATUS } from "../utils/constants";

const DonationPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage()
  const [manage, setManage] = useState(false);
  const userData = useSelector((state) => state.user);
  
  const memberData = useSelector((state) => state.member);
  const donationData = useSelector((state) => state.donation);
  const paymentData = useSelector((state) => state.payment);
  const dispatch = useDispatch();

  const donationColumns = [
    {
      name: "Profil",
      selector: (row) => {
        const member = getModelById(memberData, row.user_id);
        return (
          <img
            className="w-50 p-2"
            style={{ borderRadius: "50%" }}
            src={member.image}
            alt=""
          />
        );
      },
    },
    {
      name: "Montant",
      selector: (row) => {
        return formatPrice(row.amount, 2);
      },
    },
    {
      name: "Prénom(s)",
      selector: (row) => {
        const member = getModelById(memberData, row.user_id);
        return member.first_name;
      },
    },
    {
      name: "Nom(s)",
      selector: (row) => {
        const member = getModelById(memberData, row.user_id);
        return member.last_name;
      },
    },
    {
      name: "Date",
      selector: (row) => {
        return parseReadableDate(row.created_at);
      },
      sortable: true,
      sortFunction: (a, b) => {
        let aDate = new Date(a.created_at);
        let bDate = new Date(b.created_at);
        return aDate - bDate;
      },
    },
    {
      name: "Pays",
      selector: (row) => {
        const member = getModelById(memberData, row.user_id);
        return member.country;
      },
      sortable: true,
    },
    {
      name: "Numéro de téléphone",
      selector: (row) => {
        const member = getModelById(memberData, row.user_id);
        return member.phone;
      },
      sortable: true,
    },
    {
      name: "Adresse mail",
      selector: (row) => {
        const member = getModelById(memberData, row.user_id);
        return member.email;
      },
      sortable: true,
    },
    {
      name: "Ville",
      selector: (row) => {
        const member = getModelById(memberData, row.user_id);
        return member.city;
      },
    },
    {
      name: "Adresse",
      selector: (row) => {
        const member = getModelById(memberData, row.user_id);
        return member.address;
      },
      sortable: true,
    },
  ];

  const filterSoldedDonation = (donations) => {
    return donations.filter((donation) => {
      let payment = getDonationPayment(donation.id, paymentData);
      return payment !== null && payment.status === PAYMENT_STATUS.RECEIVED;
    });
  };

  useEffect(() => {
    if (memberData === null)
      dispatch(getMembers({ reset: false, interval: null }));
    if (donationData === null) dispatch(getDonations());
    if (paymentData === null) dispatch(getPayments());
  }, [dispatch, donationData, memberData, paymentData]);

  return (
    <AdminContainer>
      <div className="d-flex justify-content-end align-items-center">
        <div className="">
          {paymentData && userData && userData.admin === 1 && (
            <div className="d-flex gap-2">
              <button
                onClick={() => setManage(!manage)}
                className="btn border openCart fw-bold text-uppercase"
              >
                {" "}
                <i
                  className={!manage ? "fa fa-eye" : "fa fa-eye-slash"}
                ></i>{" "}
                gérer
              </button>
            </div>
          )}
        </div>
      </div>
      {!manage ? (
        <div className="col-12 col-md-10 col-xl-9">
          <DonationForm />
        </div>
      ) : (
        <div>
          {memberData && donationData ? (
            <div>
              <h4 className="fw-bold text-light text-uppercase">
                liste des dons
              </h4>
              <DataTable
                className="PaymentHistory mt-3"
                columns={donationColumns}
                data={filterSoldedDonation(donationData)}
                striped
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
              />
            </div>
          ) : (
            <CircularLoader />
          )}
        </div>
      )}
    </AdminContainer>
  );
};

export default DonationPage;
