/* eslint-disable no-unused-vars */
import { ToastContainer } from "react-toastify";
import CircleGroup from "../components/CircleGroup";
import Navbar from "../components/navigation/Navbar";
import useLanguage from "../hook/useLanguage";
import "./styles/AppContainer.css";

const AppContainer = ({ uniqueChild = false, ...props }) => {
  const [language, t, handleChangeLanguage] = useLanguage();

  return (
    <div className="AppContainer">
      <div className="custom-container mx-auto p-0 m-0">
        <div className="NavbarContainer ms-auto">
          <Navbar />
        </div>
        <div className="AppContainer__content d-flex justify-content-between flex-column-reverse flex-lg-row mt-2 mt-lg-5">
          <div className="p-0 m-0">{props.children}</div>
          {!uniqueChild && (
            <div className="rightContainer mt-2 pt-2 mt-lg-5 pt-lg-5">
              <div className="d-flex justify-content-center justify-content-lg-between align-items-center gap-5">
                <CircleGroup />
                <h1 className="first-title text-light text-nowrap">
                  RACING CLUB
                </h1>
                {/* <br className='d-none d-lg-block' />{t('FROM DOUALA')}</h1> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={3500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </div>
  );
};

export default AppContainer;
