/* eslint-disable no-unused-vars */
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import AdminContainer from "../components/AdminContainer";
import Box from "../components/Box";
import { Modal } from "react-bootstrap";
import CircularLoader from "../components/loader/CircularLoader";
import useLanguage from "../hook/useLanguage";
import "./../styles/PlayerPage.css";
import { parseReadableDate } from "../utils/utils";
import SessionService from "../services/SessionService";
import DataTable from "react-data-table-component";
// import ImageViewer from "../components/ImageViewer";

const ParticipationsPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const [showModal, setShowModal] = useState(false);
  const cancelBtnRef = useRef(null);
  const [sessions, setSessions] = useState(null);
  const [currentSession, setCurrentSession] = useState(null);
  const [participations, setParticipations] = useState(null);
  const [imageViewer, setImageViewer] = useState(null);
  const [fetchingParticipations, setFetchingParticipations] = useState(false);
  // const [
  //   // preview,
  //   setPreview,
  // ] = useState();
  // const [
  //   subscriptionToDownload,
  //   // setSubscriptionToDownload
  // ] = useState(null);

  const [formData, setFormData] = useState({
    date: "",
  });

  const [errors, setErrors] = useState({
    date: "",
  });

  const playerData = useSelector((state) => state.player);
  const userData = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!sessions) {
      SessionService.getAll().then((data) => {
        setSessions(data);
        if (data.length > 0) setCurrentSession(data[0]._id);
      });
    }
  }, [dispatch, playerData, sessions]);

  useEffect(() => {
    if (currentSession) {
      setFetchingParticipations(true);
      SessionService.getParticipations(currentSession)
        .then((data) => {
          setParticipations(data);
        })
        .catch((error) => {
          // TODO: Handle error
        })
        .finally(() => {
          setFetchingParticipations(false);
        });
    }
  }, [currentSession]);

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const handleModalCLose = (event) => {
    setShowModal(false);
    // setImagePreview('');
  };

  const validateForm = () => {
    let errors = {
      date: "",
    };

    let isValid = true;

    if (!formData.date || formData.date === "") {
      errors.date = "La date est requise";
      isValid = false;
    }

    // if (!formData.image) {
    //     errors.image = 'Image est requis';
    //     isValid = false;
    // }

    setErrors(errors);

    return isValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let data = formData;

    if (validateForm()) {
      event.target.querySelector(".spinner-grow").classList.remove("d-none");
      event.target.disabled = true;
      cancelBtnRef.current.disabled = true;

      SessionService.createSession(data)
        .then((data) => {
          toast.success("La session a été créée.");
          const tmpSessions = [...sessions].unshift(data);
          setSessions(tmpSessions);
        })
        .catch((error) => {
          if (error.response.data && Array.isArray(error.response.data)) {
            error.response.data.map((err) => toast.error(err.message));
          } else {
            toast.error("Something went wrong. Retry.");
          }
        })
        .finally(() => {
          event.target.querySelector(".spinner-grow").classList.add("d-none");
          event.target.disabled = false;
          cancelBtnRef.current.disabled = false;
        });

      return true;
    }
  };

  const candidateDataColumns = [
    {
      name: "Carte",
      selector: (row) => {
        if (row.card) {
          return (
            <img
              onClick={() => setImageViewer(row.card)}
              src={row.card}
              className="w-50 p-2"
              // style={{ borderRadius: "50%" }}
              alt=""
            />
          );
        }
        return (
          <small className="text-muted small" style={{ userSelect: "none" }}>
            non renseignée
          </small>
        );
      },
    },
    {
      name: "Date ins.",
      selector: (row) => {
        return parseReadableDate(row.updatedAt);
      },
      sortable: true,
      sortFunction: (a, b) => {
        let aDate = new Date(a.updatedAt);
        let bDate = new Date(b.updatedAt);
        return aDate - bDate;
      },
    },
    {
      name: "ID Publique",
      selector: (row) => {
        return row.candidate.publicId;
      },
    },
    {
      name: "Nom(s)",
      selector: (row) => {
        return row.candidate.lastname;
      },
      sortable: true,
    },
    {
      name: "Prénom(s)",
      selector: (row) => {
        return row.candidate.firstname;
      },
      sortable: true,
    },
    {
      name: "Poste",
      selector: (row) => {
        return row.candidate.position;
      },
      sortable: true,
    },
    {
      name: "Poste",
      selector: (row) => {
        return row.amount + " FCFA";
      },
      sortable: true,
    },
    {
      name: "Numéro de téléphone",
      selector: (row) => {
        return row.candidate.phone;
      },
      sortable: true,
    },
    {
      name: "Adresse mail",
      selector: (row) => {
        return row.candidate.email;
      },
      sortable: true,
    },
    {
      name: "Ville",
      selector: (row) => {
        return row.candidate.city;
      },
    },
    {
      name: "Adresse",
      selector: (row) => {
        return row.candidate.address;
      },
      sortable: true,
    },
  ];

  return (
    <AdminContainer>
      <div className="">
        <nav className="navbar navbar-expand-lg bg-transparent position-sticky top-0">
          <div className="container-fluid w-100 p-0">
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <h2 className="navbar-brand text-light m-0" href="#">
                PARTICIPATIONS
              </h2>
              <form
                className="d-flex ms-5"
                style={{ background: "#616667", borderRadius: "5px" }}
                role="search"
              >
                {/* <input
                  className="form-control searchBar bg-transparent"
                  style={{ color: "#fff", border: "none" }}
                  type="text"
                  placeholder="Rechercher un candidat"
                  aria-label="Search"
                  value={searchQuery}
                  onChange={(event) => setSearchQuery(event.target.value)}
                />
                <div className=" bg-transparent py-2 ps-0 pe-2">
                  <i className="fa-solid fa-magnifying-glass text-light"></i>
                </div> */}
                <select
                  className="form-control text-black"
                  onChange={(e) => setCurrentSession(e.target.value)}
                >
                  {sessions && sessions.length > 0 ? (
                    sessions.map((session, index) => (
                      <option key={index} value={session._id}>
                        {parseReadableDate(session.date, true, true)}
                      </option>
                    ))
                  ) : (
                    <option>No record to display</option>
                  )}
                </select>
              </form>
            </div>
            {userData && userData.admin === 1 ? (
              <button
                className="btn bg-transparent text-light"
                onClick={() => setShowModal(true)}
              >
                + Nouvelle session
              </button>
            ) : null}
          </div>
        </nav>

        <div
          className="container-fluid w-100 p-0"
          style={{ minHeight: "100vh", overflow: "hidden" }}
        >
          {!fetchingParticipations && participations && (
            <DataTable
              className="PaymentHistory"
              columns={candidateDataColumns}
              data={participations}
              striped
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            />
          )}

          {fetchingParticipations && <Box content={<CircularLoader />} />}
        </div>
      </div>

      <Modal show={showModal} onHide={handleModalCLose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Ajouter une session</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="form-group mt-3">
              <label htmlFor="position">Date</label>
              <input
                type="date"
                className="form-control text-dark"
                id="date"
                name="date"
                value={formData.date}
                onChange={handleFormChange}
              />
              {errors.date && <div className="text-danger">{errors.date}</div>}
            </div>
          </form>
          {/* {showModalDelete && (
            <div className="text-start fs-5 fw-bold">
              Voulez vous supprimer Ce joueur?
            </div>
          )} */}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleModalCLose}
            ref={cancelBtnRef}
          >
            Annuler
          </button>
          <button className="btn btn-primary" onClick={handleSubmit}>
            <div
              className="spinner-grow spinner-grow-sm d-none mx-2"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
            Enregistrer
          </button>
        </Modal.Footer>
      </Modal>
    </AdminContainer>
  );
};

export default ParticipationsPage;
