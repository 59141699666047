import axios from 'axios';
// import jsPDF from 'jspdf';
import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux';
// import useLanguage from '../../hook/useLanguage';
import { getCards } from '../../redux/slices/cardSlice';
import { getCarts } from '../../redux/slices/cartSlice';
import { getCommands } from '../../redux/slices/commandSlice';
import { getItems } from '../../redux/slices/itemSlice';
import { getMembers } from '../../redux/slices/memberSlice';
import { getPayments } from '../../redux/slices/paymentSlice';
import { getPrintRequests } from '../../redux/slices/printRequestSlice';
import { getSeasons } from '../../redux/slices/seasonSlice';
import { getSubscriptions } from '../../redux/slices/subscriptionSlice';
import { formatPrice, getModelById, getSubscriptionPayment, parsePaymentMode, parseReadableDate } from '../../utils/utils';
import Box from '../Box';
import ImageViewer from '../ImageViewer';
import CircularLoader from '../loader/CircularLoader';
import { PAYMENT_STATUS } from '../../utils/constants';


const CardList = () => {
    // const [language, t, handleChangeLanguage] = useLanguage()
    const cardData = useSelector((state) => state.card);
    const subscriptionData = useSelector((state) => state.subscription);
    const commandData = useSelector((state) => state.command);
    const cartData = useSelector((state) => state.cart);
    const paymentData = useSelector((state) => state.payment);
    const seasonData = useSelector((state) => state.season);
    const printData = useSelector((state) => state.printRequest);
    const itemData = useSelector((state) => state.item);
    // const userData = useSelector((state) => state.user);
    const memberData = useSelector((state) => state.member);

    const [imageViewer, setImageViewer] = useState(null);
    const [seasonViewer, setSeasonViewer] = useState(null);
    // const [subscriptionToPrint, setSubscriptionToPrint] = useState(null);
    // const [subscriptionToGenerate, setSubscriptionToGenerate] = useState(null);
    const [subscriptionToDownload,
        // setSubscriptionToDownload
    ] = useState(null);
    const [
        // preview,
        setPreview] = useState();
    const dispatch = useDispatch();

    useEffect(() => {
        if (cardData === null) dispatch(getCards());
        if (paymentData === null || paymentData.length === 0) dispatch(getPayments());
        if (seasonData === null) dispatch(getSeasons());
        if (subscriptionData === null || subscriptionData.length === 0) dispatch(getSubscriptions());
        if (commandData === null) dispatch(getCommands());
        if (printData === null) dispatch(getPrintRequests());
        if (itemData === null) dispatch(getItems());
        if (cartData === null) dispatch(getCarts());
        if (memberData === null || memberData.length === 0) dispatch(getMembers({ reset: false, interval: null }));
    }, [cardData, cartData, commandData, dispatch, itemData, memberData, paymentData, printData, seasonData, subscriptionData])



    const cardDataColumns = [
        {
            name: 'Profile',
            selector: (row) => {
                let member = getModelById(memberData, row.user_id)
                return <img onClick={() => setImageViewer(member.image)}
                    style={{ borderRadius: '50%' }}
                    className={`img-fluid w-50 m-2 ` + (parseInt(member.external) === 1 ? 'img-external' : '')}
                    src={member.image} alt={member.first_name} />
            }
        },
        {
            name: 'Nom(s) et Prénom(s)',
            selector: (row) => {
                let member = getModelById(memberData, row.user_id)
                return <div className={(parseInt(member.external) === 1 ? 'name-external' : '')}>{member.last_name + ' ' + member.first_name}</div>
            },
            sortable: true,
        },
        {
            name: 'Type de carte',
            selector: (row) => {
                let card = getModelById(cardData, row.card_id)
                // let member = getModelById(memberData, row.user_id)
                return card.type
            },
            sortable: true,
        },
        {
            name: 'Saison',
            selector: row => (getModelById(seasonData, row.season_id)?.name.split(' ')[1]),
            sortable: true,
        },
        {
            name: 'Rendu',
            selector: (row) => {
                let card = getModelById(cardData, row.card_id)
                // let member = getModelById(memberData, row.user_id)
                // let season = getModelById(seasonData, row.season_id)
                return <img onClick={() => { setImageViewer(row.image); setSeasonViewer(card.verso) }} src={row.image} alt='' className="img-fluid w-50" />
            }
        },
        {
            name: 'Prix',
            selector: (row) => {
                let card = getModelById(cardData, row.card_id)
                return formatPrice(card?.card_price)
            },
            sortable: true,
            sortFunction: (a, b) => {
                let aPrice = getModelById(cardData, a.card_id)?.card_price
                let bPrice = getModelById(cardData, b.card_id)?.card_price
                return aPrice - bPrice;
            }
        },
        {
            name: 'Achété le',
            selector: (row) => { return parseReadableDate(row.created_at) },
            sortable: true,
            sortFunction: (a, b) => {
                let aDate = new Date(a.created_at)
                let bDate = new Date(b.created_at)
                return aDate - bDate;
            }
        },
        {
            name: 'Status',
            selector: (row) => {
                let payment = getSubscriptionPayment(row.id, paymentData)
                return payment.status === PAYMENT_STATUS.RECEIVED ?
                    <div style={{ color: "rgb(0, 238, 161)" }}> <i className="fa fa-check-circle" aria-hidden="true"></i> payé</div> :
                    <div style={{ borderBottom: "1px dashed rgb(238, 68, 161)", color: "rgb(238, 68, 161)" }}>en attente</div>
            },
            sortable: true,
            sortFunction: (a, b) => {
                let aStatus = getSubscriptionPayment(a.id, paymentData).status === PAYMENT_STATUS.RECEIVED ? 1 : 0
                let bStatus = getSubscriptionPayment(b.id, paymentData).status === PAYMENT_STATUS.RECEIVED ? 1 : 0
                return aStatus - bStatus;
            }

        },
        {
            name: 'Mode de paiement',
            selector: (row) => {
                // let card = getModelById(cardData, row.card_id)
                // let member = getModelById(memberData, row.user_id)
                let payment = getSubscriptionPayment(row.id, paymentData)
                return parsePaymentMode(payment.payment_method)
            },
            sortable: true,
        },
    ];

    // const [key, setKey] = useState(0);
    const [q, setQ] = useState(null)

    const customTextFilter = (data, search) => {
        if (search === null) {
            return data.filter((val, key) => {
                let payment = getSubscriptionPayment(val.id, paymentData)
                return payment.status === PAYMENT_STATUS.RECEIVED
            })
        }
        search = search.toLowerCase()
        return data.filter((val, key) => {
            let member = getModelById(memberData, val.user_id)
            let payment = getSubscriptionPayment(val.id, paymentData)

            return payment.status === PAYMENT_STATUS.RECEIVED && (member.first_name.toLowerCase().includes(search) || member.last_name.toLowerCase().includes(search))
        })
    }

    const handlePrint = (e) => {
        const innerHTML = e.target.innerHTML;
        e.preventDefault()
        e.target.innerHTML = '<div className="spinner-border spinner-border-sm text-warning" role="status">' +
            '<span className="sr-only">Loading...</span></div> impression en cours...'
        axios({
            method: 'get',
            url: '/cards-report',
            responseType: 'blob'
        }).then((response) => {
            // Create a download link for the PDF
            const downloadLink = document.createElement('a');
            downloadLink.href = window.URL.createObjectURL(new Blob([response.data]));
            const date = new Date();
            const fileName = `rapport-de-ventes-des-cartes-membres-${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}.pdf`;
            downloadLink.setAttribute('download', fileName);

            // Trigger the download
            downloadLink.click();
        }).finally(() => {
            e.target.innerHTML = innerHTML;
        })


    }

    return <div className='mb-5'>
        {subscriptionData !== null && commandData && memberData && cartData !== null && itemData !== null && seasonData !== null && printData !== null && paymentData !== null ? (
            <>
                <div className="d-flex justify-content-between w-100 my-3 align-items-center">
                    <input maxLength={10} placeholder="Recherchez un membre" onChange={(e) => setQ(e.target.value)} className="w-50 searchBar bg-transparent form-control mr-sm-2" type="search" aria-label="Search"></input>

                    <button onClick={handlePrint} className='btn border openCart fw-bold text-uppercase'> <i className='fa fa-print'></i> imprimer</button>

                </div>
                <DataTable className='PaymentHistory mt-3'
                    columns={cardDataColumns}
                    data={customTextFilter(subscriptionData, q)}
                    striped
                    pagination
                    paginationPerPage={5}
                    paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
                />
            </>

        ) : (
            <Box content={<CircularLoader />} />
        )}

        {imageViewer &&
            <ImageViewer image={imageViewer} season={seasonViewer} onClose={() => { setImageViewer(null); setSeasonViewer(null); setPreview(null) }} open={subscriptionToDownload !== null} />
        }



    </div>
}

export default CardList;