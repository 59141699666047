import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { VoteService } from "../../services/VoteService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = [];

export const getVotes = createAsyncThunk(
  "vote/all",
  async () => {
    const res = await VoteService.all();
    //console.log(res.data);
    return res.data;
  }
);

export const addVote = createAsyncThunk(
  "vote/add",
  async (vote) => {
    const res = await VoteService.add(vote);
    return res.data;
  }
);

export const makeVote = createAsyncThunk(
  "vote/make",
  async (vote) => {
    const res = await VoteService.make(vote);
    return res.data;
  }
);


export const updateVote = createAsyncThunk(
  "vote/update",
  async (vote) => {
    const res = await VoteService.update(vote);
    return res.data;
  }
);

const voteSlice = createSlice({
  name: "vote",
  initialState,
  extraReducers: {
    [getVotes.fulfilled]: (state, action) => {
      return action.payload
    },
    [addVote.fulfilled]: (state, action) => {
      if (state) {
        state = [...state, action.payload.data]
      } else {
        state = [action.payload.data]
      }
      return state
    },
    [updateVote.fulfilled]: (state, action) => {
      const index = state.findIndex(player => player.id === action.payload.id);
      state[index] = {
        ...state[index],
        ...action.payload,
      };
      return state
    },

  },
});

const { reducer } = voteSlice;
export default reducer;