import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import PrintRequestService from "../../services/PrintRequestService.js";
import { handlePayment } from "./paymentSlice";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getPrintRequests = createAsyncThunk(
  "print_request/all",
  async () => {
    const res = await PrintRequestService.getAll();
    return res.data;
  }
);
export const getPrintRequestsAdmin =createAsyncThunk(
  "print_request/all",
  async ({reset, interval}) => {
    if(reset){
      return initialState
    }
    const res = await PrintRequestService.getAllAdmin(interval);
    //console.log(res.data);
    return res.data;
  }
);

const printRequestSlice = createSlice({
  name: "print_request",
  initialState,
  extraReducers: {
    [getPrintRequests.fulfilled]: (state, action) => {
        return action.payload
    },
    [getPrintRequestsAdmin.fulfilled]: (state, action) => {
        return action.payload
    },
    [handlePayment.fulfilled]: (state, action) => {
      if (state === null) {
        state = []
      }
      if(action.payload.data.print_request){
        state.push(action.payload.data.print_request)
      }
      return state
    },

  },
});

const { reducer } = printRequestSlice;
export default reducer;