/* eslint-disable eqeqeq */
/* eslint-disable no-useless-escape */
import { parsePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";

export const obfuscatePhoneNumber = (phone) => {
  if (!phone) {
    return null;
  }
  return Array.from(phone)
    .map((value, index, arr) => {
      if (index !== 0 && index <= arr.length / 3) {
        return "*";
      } else {
        return value;
      }
    })
    .join("");
};

export const filterGame = (games, played = false) => {
  return games
    .filter((game, index) => {
      let gameEndAt = new Date(game.end_at);
      let now = new Date();
      if (played) return now - gameEndAt >= 0;
      else return now - gameEndAt < 0;
    })
    .sort((a, b) => {
      return new Date(a.start_at) - new Date(b.start_at);
    });
};

export const adaptTeamName = (name) => {
  return name.split(" ").map((item) => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-center">
          {item}
        </div>
      </>
    );
  });
};

const getPlayerVoteResult = (voteData, playerId) => {
  let voting = 0;
  voteData.votes.forEach((vote) => {
    if (vote[1] == playerId) {
      voting++;
    }
  });
  return voting;
};

export const getBestVote = (voteData, playerData) => {
  let bestVoting = {
    voting: 0,
    player: null,
  };
  voteData.players_id.forEach((id) => {
    const voting = getPlayerVoteResult(voteData, id);
    if (bestVoting.voting < voting) {
      bestVoting = { voting: voting, player: getModelById(playerData, id) };
    }
  });
  return bestVoting;
};

export function nFormatter(num, digits) {
  if (num < 1000) return formatPrice(num);
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export const getOnlyOneName = (name) => {
  if (name) {
    if (name.includes(" ")) {
      return name.split(" ")[0];
    }
    return name;
  }
};

export const formatsCardNumber = (e) => {
  let ele = e.target;
  if (ele.value.length < 19) {
    ele.value = ele.value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
    return true;
  } else {
    return false;
  }
};

export const parseReadableDate = (date, jsx = true, skipTime = false) => {
  // const today = new Date();
  // let day = getDayweek(date),
  let month = getMonth(date),
    dateDay = new Date(date).getDate(),
    year = new Date(date).getFullYear(),
    hour = new Date(date).getHours(),
    minutes = new Date(date).getMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  hour = hour < 10 ? `0${hour}` : hour;
  let output = skipTime
    ? dateDay + " " + month + " " + year
    : dateDay + " " + month + " " + year + " à " + hour + "h" + minutes;
  // if (today.getFullYear()===year) {
  //   output = dateDay + ' ' + month + ' ' +
  //     ' à ' +
  //     hour + 'h' + minutes
  //   if (today.getMonth()===(new Date(date)).getMonth()) {
  //     if (today.getDate()===(new Date(date)).getDate()) {
  //         output = hour + 'h' + minutes
  //     } else if (today.getDate() - 1===(new Date(date)).getDate()){
  //       output = 'Hier à '+ hour + 'h' + minutes
  //     }
  //   }
  // }
  if (!jsx) return output;
  return <span title={output}> {output}</span>;
};

export const formats = (e) => {
  let ele = e.target;
  if (ele.value.length < 19) {
    ele.value = ele.value.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");
    return true;
  } else {
    return false;
  }
};

export const formatPrice = (price) => {
  //  return price
  return new Intl.NumberFormat("cm-CM", {
    style: "currency",
    currency: "XAF",
  }).format(price);
};

export const formatNumber = (value) => {
  return new Intl.NumberFormat("en-IN").format(value);
};

export const convertTo5Multiple = (value) => {
  let amountToSend = Math.ceil(value);
  amountToSend = amountToSend + (5 - (amountToSend % 5));
  return amountToSend;
};

export const formatsExpires = (e) => {
  let ele = e.target;
  if (ele.value.length < 7) {
    ele.value = ele.value.replace(/\W/gi, "").replace(/(.{2})/g, "$1/");
    return true;
  } else {
    return false;
  }
};

export const numberValidation = (e) => {
  e.target.value = e.target.value.replace(/[^\d ]/g, "");
  return false;
};

export const getModelById = (models = [], id) => {
  let model = null;
  for (let index = 0; index < models.length; index++) {
    if (models[index].id == id) {
      //console.log(models[index]);
      model = models[index];
    }
  }
  return model;
};

export const getCartById = (models, id) => {
  let model = null;
  for (let index = 0; index < models.length; index++) {
    if (models[index].command_id == id) {
      model = models[index];
    }
  }

  return model;
};

export const formatDate = (date) => {
  let year = "" + date.getFullYear(),
    month = date.getMonth() + 1 + "",
    day = "" + date.getDate();
  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getCartsById = (models, id) => {
  let carts = [];
  let i = 0;
  for (let index = 0; index < models.length; index++) {
    if (models[index].command_id == id) {
      carts[i] = models[index];
      i++;
    }
  }
  //console.log(carts);
  return carts;
};

export const getItemsById = (models, id) => {
  let items = [];
  let i = 0;
  for (let index = 0; index < models.length; index++) {
    if (models[index].items_id == id) {
      items[i] = models[index];
      i++;
    }
  }
  //console.log(items);
  return items;
};
export const getSubscriptionPrintRequest = (id, printRequests) => {
  let model = null;
  for (let index = 0; index < printRequests.length; index++) {
    if (printRequests[index].subscription_id == id) {
      model = printRequests[index];
    }
  }

  return model;
};

export const getSubscriptionPayment = (id, payments) => {
  let model = null;
  for (let index = 0; index < payments.length; index++) {
    if (payments[index].subscription_id == id) {
      model = payments[index];
    }
  }

  return model;
};

export const getPrintRequestPayment = (id, payments) => {
  let model = null;
  for (let index = 0; index < payments.length; index++) {
    if (payments[index].print_request_id == id) {
      model = payments[index];
    }
  }

  return model;
};

export const getCommandPayment = (id, payments) => {
  let model = null;
  for (let index = 0; index < payments.length; index++) {
    if (payments[index].command_id == id) {
      model = payments[index];
    }
  }

  return model;
};

export const getDonationPayment = (id, payments) => {
  let model = null;
  for (let index = 0; index < payments.length; index++) {
    if (payments[index].donation_id == id) {
      model = payments[index];
    }
  }

  return model;
};

export const getCommandCart = (carts, command_id) => {
  return carts.filter((cart) => {
    return cart.command_id == command_id;
  });
};

export function isPeriod(ratings, ratingPeriod) {
  let is_Period = false;
  if (
    Array.isArray(ratings) &&
    ratings.length &&
    Array.isArray(ratingPeriod) &&
    ratingPeriod.length
  ) {
    for (let index = 0; index < ratings.length; index++) {
      if (ratings[index].rating_periods_id == ratingPeriod[0].id) {
        is_Period = true;
      }
    }
  }
  console.log(is_Period);
  return is_Period;
}

export function isMeeting(ratings, meeting) {
  let is_meeting = false;
  if (
    Array.isArray(ratings) &&
    ratings.length &&
    Array.isArray(meeting) &&
    meeting.length
  ) {
    for (let index = 0; index < ratings.length; index++) {
      if (ratings[index].meetings_id == meeting[0].id) {
        is_meeting = true;
      }
    }
  }
  console.log(is_meeting);
  return is_meeting;
}

export const getDayweek = (date) => {
  let ladate = new Date(date);
  let tab_jour = [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
  ];
  return tab_jour[ladate.getDay()];
};

export const getMonth = (date) => {
  let ladate = new Date(date);
  let tab_mois = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  return tab_mois[ladate.getMonth()];
};

// Using fetch
export async function downloadImage(imageSrc, imageName) {
  console.log(imageSrc);
  const image = await fetch(imageSrc);
  const imageBlog = await image.blob();
  const imageURL = URL.createObjectURL(imageBlog);

  const link = document.createElement("a");
  link.href = imageURL;
  link.download = imageName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const getHostName = () => {
  const protocol = window.location.protocol;
  const domain = window.location.hostname;
  const port = window.location.port;

  return `${protocol}//${domain}${port ? ":" + port : ""}`;
};

export const parsePaymentMode = (mode) => {
  switch (mode) {
    case "cc":
      return "carte de crédit";
    case "om":
      return "orange money";
    case "momo":
      return "mtn money";
    default:
      return null;
  }
};

export const getModalVerificationMessage = (country, mail, phone) => {
  // console.log(country, mail, phone);
  if (country && !country.localeCompare("cameroon")) {
    if (mail) {
      return (
        "Nous vous avons envoyé un code de vérification à 6 chiffres à l'adresse " +
        obfuscatePhoneNumber(mail)
      );
    }
  } else {
    if (mail && phone) {
      return (
        "Nous vous avons envoyé un code de vérification à 6 chiffres à " +
        obfuscatePhoneNumber(phone) +
        " et à l'adresse " +
        obfuscatePhoneNumber(mail)
      );
    } else if (mail) {
      return (
        "Nous vous avons envoyé un code de vérification à 6 chiffres à l'adresse " +
        obfuscatePhoneNumber(mail)
      );
    } else if (phone) {
      return (
        "Nous vous avons envoyé un code de vérification à 6 chiffres au numéro " +
        obfuscatePhoneNumber(phone)
      );
    } else {
      return "Nous vous avons envoyé un code de vérification à 6 chiffre";
    }
  }
};
// [0, 1, 2, 3]
// lenght = 4

//   i         j      tab
//   3         1       [0, 3, 2, 1]
//   2         0       [2, 3, 0, 1]
//   1         1       [2, 3, 0, 1]

export const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
};

export function isEmail(email) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

export async function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const authFormValidator = (name, value) => {
  if (name === "last_name") {
    if (!/^[a-zA-Z]{3,}$/.test(value)) return "incorrect last name";
  }
  if (name === "first_name") {
    if (!/^([a-zA-Z]){3,}$/.test(value)) return "incorrect first name";
  }
  if (name === "email") {
    if (!isEmail(value)) return "incorrect email";
  }
  if (name === "city") {
    if (value.length < 2) return "incorrect city";
  }
  if (name === "address") {
    if (value.length < 4) return "incorrect address";
  }
  if (name === "phone") {
    if (!isValidPhoneNumber(value)) return "incorrect phone";
  }
  if (name === "password") {
    if (value.length < 6) return "incorrect password";
  }
  if (name === "id") {
    // email or phone number
    if (
      !isEmail(value) &&
      !isValidPhoneNumber(parsePhoneNumber(value, "CM").number)
    ) {
      return "incorrect email or phone number";
    }
  }
  return null;
};
export const authChangePasswordFormValidator = (name, value) => {
  if (name === "password") {
    if (value.length < 6) return "password length must be greather than 6";
  }
  if (name === "__password") {
    if (value.length < 6) return "password length must be greather than 6";
  }
  if (name === "id") {
    // email or phone number
    if (!isEmail(value) && !isValidPhoneNumber(value)) {
      return "incorrect email or phone number";
    }
  }
  return null;
};
