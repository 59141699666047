/* eslint-disable no-unused-vars */
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import AppContainer from "../components/AppContainer";
import LoginForm from "../components/form/LoginForm";
import useLanguage from "../hook/useLanguage";
import "./../styles/LoginPage.css";
import useAuthToken from "../hook/useAuthToken";

const LoginPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  /* const authToken = useAuthToken();
  if (authToken) {
    window.location = '/'
  } */
  return (
    <AppContainer>
      <LoginForm />
      <div className="d-flex mt-5 justify-content-start align-items-center gap-3">
        <div className="text-light text-white fw-bold">
          {t("Already have an account?")}
        </div>
        <NavLink className="btn btn-primary joinTeamBtn" to="/register">
          {t("Join the team")}
        </NavLink>
      </div>
    </AppContainer>
  );
};

export default LoginPage;
