import { useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * You can use this hook to manage localisation in the RACING App
 *
 * language: the current app language
 *
 * t: translation function
 *
 * handleChangeLanguage: for change the current language \n
 *
 * @returns [language, t, handleChangeLanguage]
 */

const useLanguage = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(
    localStorage.getItem("defaultLang") ?? "en"
  );

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
    localStorage.setItem("defaultLang", lang);
  };

  return [language, t, handleChangeLanguage];
};

export default useLanguage;
