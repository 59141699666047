const baseUri = process.env.REACT_APP_RECRUIT;
// const baseUri = "http://localhost:5500/api";


const getAll = async () => {
  const response = await fetch(`${baseUri}/payments`);
  const data = await response.json();
  console.log({data});
  return data;
};

const CandidatePaymentService = {
  getAll,
};

export default CandidatePaymentService;
