import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CartService from "../../services/CartService.js";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getCarts = createAsyncThunk(
    "carts/all",
    async () => {
        const res = await CartService.getAll();
        return res.data;
    }
);

export const getCartsAdmin = createAsyncThunk(
    "carts/all",
    async ({ reset, interval }) => {
        if (reset) {
            return initialState
        }
        const res = await CartService.getAllAdmin(interval);
        //console.log(res.data);
        return res.data;
    }
  );

export const addCart = createAsyncThunk(
    "carts/add",
    async (cart) => {
        const res = await CartService.addCart(cart);
        return res.data;
    }
);

export const removeCart = createAsyncThunk(
    "carts/remove",
    async (cart) => {
        const res = await CartService.removeCart(cart);
        return res.data;
    }
);


const cartSlice = createSlice({
    name: "cart",
    initialState,
    extraReducers: {
        [getCarts.fulfilled]: (state, action) => {
            return action.payload
        },
        [getCartsAdmin.fulfilled]: (state, action) => {
            return action.payload
        },
        [addCart.fulfilled]: (state, action) => {
            if (state===null) {
                state = [action.payload.data];
            } else {
                const cart = action.payload.data;
                let cartFound = false;
                state = state.map((value, index) => {
                    if (value.id===cart.id) { cartFound = true; return cart }
                    return value;
                })
                !cartFound && state.push(cart)
            }
            return state
        },
        [removeCart.fulfilled]: (state, action) => {
            const cart = action.payload.data;
            state = state.filter((value, index) => value.id !== cart.id)
            return state
        },
    },
});

const { reducer } = cartSlice;
export default reducer;