/* eslint-disable no-unused-vars */
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useRef, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useLanguage from "../../hook/useLanguage";
import PaymentService from "../../services/PaymentService";
import { formatPrice } from "../../utils/utils";
import CircularLoader from "../loader/CircularLoader";
import { PaymentMethod } from "../PaymentMethod";
import "./../styles/StripeDonationForm.css";
import { PAYMENT_STATUS } from "../../utils/constants";
import { createDonation } from "../../redux/slices/donationSlice";
import Alert from "../Alert";

const PaymentForm = ({
  onGoBack,
  amount,
  title,
  stripe_fee,
  dataForPayment,
  onPaymentSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const mobileFormRef = useRef();
  const cardFormRef = useRef();
  const operatorRef = useRef();
  const phoneRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [message, setMessage] = useState(null);
  const [onPayment, setOnPayment] = useState(false);
  const [onWaitingMobilePayment, setOnWaitingMobilePayment] = useState(false);
  const [pendingPaymentId, setPendingPaymentId] = useState(null);
  const [onStripePayment, setOnStripePayment] = useState(false);
  const processPhoneNumber = (phoneNumber) => {
    // Remove the country code (+237)
    const cleanNumber = phoneNumber.replace(/^\+237/, "");

    const checkOperator = (prefixes) =>
      prefixes.some((prefix) => cleanNumber.startsWith(prefix));

    const orangePrefixes = [
      "655",
      "656",
      "657",
      "658",
      "659",
      "690",
      "691",
      "692",
      "693",
      "694",
      "695",
      "696",
      "697",
      "698",
      "699",
    ];
    const mtnPrefixes = [
      "650",
      "651",
      "652",
      "653",
      "654",
      "680",
      "681",
      "682",
      "683",
      "684",
      "685",
      "686",
      "687",
      "688",
      "689",
    ];

    let operator = "Unknown";
    if (checkOperator(orangePrefixes)) {
      operator = { prefix: "om", value: "Orange Money" };
    } else if (checkOperator(mtnPrefixes)) {
      operator = { prefix: "momo", value: "MTN Money" };
    }

    return {
      phoneNumber: cleanNumber,
      operator: operator,
    };
  };

  const phoneValue = dataForPayment.phone;
  const { phoneNumber, operator } = processPhoneNumber(phoneValue);

  const [paymentMethod, setPaymentMethod] = useState({
    value: operator.prefix,
    name: operator.value,
  });
  const phoneNumberValue = phoneNumber;
  const [formValues, setFormValues] = useState({ phone: phoneNumberValue });
  var paymentStatusCheckerId;
  var paymentStatusCheckerEllapsed = 0;

  const [language, t, handleChangeLanguage] = useLanguage();

  const handleOpenForm = (e) => {
    if (e.target.value === "mobile") {
      mobileFormRef.current.classList.add("open");
      cardFormRef.current.classList.remove("open");
    } else {
      cardFormRef.current.classList.add("open");
      mobileFormRef.current.classList.remove("open");
    }
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // const startCheckingPaymentStatus = (id_payment) => {
  //   paymentStatusCheckerId = setInterval(() => {
  //     PaymentService.check(id_payment)
  //       .then((data) => {
  //         if (paymentStatusCheckerEllapsed >= 1000 * 120) {
  //           //           clearInterval(paymentStatusCheckerId);
  //           //           setOnPayment(false);
  //           //           setOnWaitingMobilePayment(false);
  //           //           toast.warn("La transaction a été annulé.", { autoClose: false });
  //         }

  //         const status = data.data.status;
  //         if (status === PAYMENT_STATUS.RECEIVED) {
  //           //           clearInterval(paymentStatusCheckerId);
  //           //           setOnPayment(false)
  //           //           setOnWaitingMobilePayment(false)
  //           //           handlePaymentSuccess()
  //         } else if (status === PAYMENT_STATUS.REJECTED) {
  //           clearInterval(paymentStatusCheckerId);
  //           setOnPayment(false);
  //           setOnWaitingMobilePayment(false);
  //           toast.warn(
  //             "Le paiement a été rejété, veuillez réessayer après quelque minutes svp.",
  //             { autoClose: true }
  //           );
  //           return;
  //         }

  //         paymentStatusCheckerEllapsed += 5000;
  //       })
  //       .catch((err) => {
  //         console.error(err);
  //       });
  //   }, 4000);
  // };

  const startCheckingPaymentStatus = async (id_payment) => {
    let paymentStatusCheckerEllapsed = 0;
    const maxWaitTime = 1000 * 120; // Temps maximal d'attente (ex. 120 secondes)
    const checkInterval = 4000; // Intervalle de vérification (ex. 4 secondes)
    let progressCounter = 0;
    const maxProgressAttempts = 40;
    const INITIALIZE = "Progress";
    let PROGRESS = "Progress";

    while (INITIALIZE === PROGRESS) {
      try {
        const { data } = await PaymentService.check(id_payment); // Déplacer la requête ici
        PROGRESS = data ?? data.status;

        console.log("Status:", PROGRESS);

        if (PROGRESS === PAYMENT_STATUS.RECEIVED) {
          // Paiement réussi
          setOnPayment(false);
          setOnWaitingMobilePayment(false);
          handlePaymentSuccess();
          break;
        } else if (PROGRESS === PAYMENT_STATUS.REJECTED) {
          // Paiement rejeté

          setOnPayment(false);
          setOnWaitingMobilePayment(false);

          toast.warn(
            "Le paiement a été rejeté, veuillez réessayer après quelques minutes.",
            { autoClose: true }
          );
          break;
        }
      } catch (err) {
        console.error(
          "Erreur lors de la vérification du statut du paiement :",
          err
        );
        toast.error(
          "Une erreur s'est produite lors de la vérification du paiement."
        );
      }

      // Attendre avant de vérifier à nouveau
      await delay(checkInterval);
      paymentStatusCheckerEllapsed += checkInterval;
    }

    if (paymentStatusCheckerEllapsed >= maxWaitTime) {
      setOnPayment(false);
      setOnWaitingMobilePayment(false);
      toast.warn("La transaction a été annulée après un délai d'attente.");
    }
  };

  const handleMobilePayment = (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneRef.current.value, "CM")) {
      return toast.error("Veuillez entrez un numéro de téléphone valide.");
    }

    const data = {
      payment_method: operatorRef.current.value,
      ...dataForPayment,
      phone: phoneRef.current.value,
    };

    setOnPayment(true);
    dispatch(createDonation(data))
      .unwrap()
      .then((data) => {
        if (data.errors) {
          toast.error(data.errors.join("<br/>"));
          setOnPayment(false);
        } else {
          setOnPayment(false);

          setOnWaitingMobilePayment(true);
          toast.info("Veuillez valider le paiement sur votre mobile svp.", {
            autoClose: true,
          });
          console.log("Data from request", { data });

          if (data.payment.id !== undefined) {
            startCheckingPaymentStatus(data.payment.id);
          }
        }
      })
      .catch((err) => {
        console.log(err);

        toast.error(
          "Une erreur s'est produite durant le paiement, veuillez vérifier vos informations, et réessayer svp."
        );
        setOnPayment(false);
      });
  };

  const handleStripePayment = async (e, stripe, elements) => {
    e.preventDefault();
    setOnStripePayment(true);
    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);
    if (error) {
      toast.error("Error: " + error.message);
    } else {
      // ... SEND to your API server to process payment intent
      const data = {
        payment_method: "cc",
        stripe_data: token,
        phone: phoneRef.current.value,
        ...dataForPayment,
      };

      dispatch(createDonation(data))
        .unwrap()
        .then((data) => {
          if (data.errors) {
            toast.error(data.errors.join("<br/>"));
            setOnStripePayment(false);
          } else {
            handlePaymentSuccess(data);
          }
        })
        .catch((err) => {
          toast.error(
            "Une erreur s'est produite durant le paiement, veuillez vérifier vos informations, votre solde et réessayer svp."
          );
          setOnStripePayment(false);
          console.log(err);
        });
    }
  };

  const handlePaymentSuccess = () => {
    // rediriger l'utilisateur et afficher sa carte
    setOnPayment(false);
    setOnStripePayment(false);
    setOnWaitingMobilePayment(false);
    setMessage(
      t(
        "Merci pour votre contribution au développement du football dans notre communauté !"
      )
    );
    toast.success(
      "Merci pour votre contribution au développement du football dans notre communauté !",
      { autoClose: false }
    );
  };

  const handleSelectPaymentMethod = (method) => {
    setPaymentMethod(method);
    if (["om", "momo"].indexOf(method.value) >= 0) {
      operatorRef.current.value = method.value;
      handleOpenForm({ target: { value: "mobile" } });
    } else {
      handleOpenForm({ target: { value: "cc" } });
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <>
      {!onPayment && !onWaitingMobilePayment ? (
        <div className="paymentForm StripeDonationForm w-100 d-flex p-4 flex-column">
          <h5 className="d-flex gap-3">
            <i
              onClick={onGoBack}
              className="backButton fa fa-arrow-left-long fa-1x"
            ></i>{" "}
            {title}
          </h5>
          <Alert message={message} type={"success"} />
          {!message && (
            <div className="col-12 mx-auto px-4">
              <h4 className="text-light fw-bold text-uppercase">
                méthode de paiement
              </h4>
              <PaymentMethod
                className={"w-100 d-flex justify-content-between"}
                onSelect={handleSelectPaymentMethod}
              />
              <form
                onSubmit={handleMobilePayment}
                ref={mobileFormRef}
                className="mobileForm w-100 open"
              >
                <div className="form-check p-0 m-0 py-1">
                  <label
                    className="form-check-label text-left"
                    htmlfor="flexRadioDefault2"
                  >
                    {t("Payment by")} {paymentMethod.name} (
                    {formatPrice(dataForPayment.amount)})
                  </label>
                </div>

                <div className="form-floating py-1 visually-hidden">
                  <select
                    ref={operatorRef}
                    name="operator"
                    className="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                  >
                    <option value="om">Orange</option>
                    <option value="momo">MTN</option>
                  </select>
                  <label htmlfor="floatingSelect" className="text-dark">
                    {t("Select mobile operator")}
                  </label>
                </div>
                <div className="form-floating mb-3 pt-2">
                  <input
                    required
                    ref={phoneRef}
                    value={formValues.phone}
                    onChange={handleChange}
                    name="phone"
                    type="tel"
                    className="form-control"
                    id="floatingInput"
                    placeholder="numéro de téléphone"
                  />
                  <label htmlfor="floatingInput">{t("Phone number")}</label>
                </div>
                <button type="submit" className="btn w-100 btn-primary my-2">
                  {t("make my donation")}{" "}
                  <i className="fas ms-2 fa-handshake"></i>
                </button>
              </form>

              <form
                onSubmit={(event) =>
                  handleStripePayment(event, stripe, elements)
                }
                ref={cardFormRef}
                className="mobileForm"
              >
                <div className="form-check p-0 m-0 py-1">
                  <label
                    className="form-check-label text-left"
                    htmlfor="flexRadioDefault1"
                  >
                    {t("Payment by")} {paymentMethod.name} (
                    {formatPrice(dataForPayment.amount)})
                  </label>
                </div>

                <div className="CardElement my-4 py-4 form-control">
                  <CardElement />
                </div>
                <button
                  disabled={!stripe || onStripePayment}
                  type="submit"
                  className="btn w-100 btn-primary my-2"
                >
                  {!onStripePayment ? (
                    <>
                      {t("make my donation")}{" "}
                      <i className="fas ms-2 fa-handshake"></i>
                    </>
                  ) : (
                    <CircularLoader />
                  )}
                </button>
              </form>
            </div>
          )}
        </div>
      ) : (
        <CircularLoader
          content={
            !onWaitingMobilePayment ? (
              <small className="text-muted">paiement en cours</small>
            ) : (
              "Veuillez confirmer le paiement sur votre téléphone mobile SVP."
            )
          }
        />
      )}
    </>
  );
};

const StripeDonationForm = (props) => <PaymentForm {...props} />;

export default StripeDonationForm;
