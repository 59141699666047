import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import CardService from "../../services/CardService";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getCards = createAsyncThunk(
  "cards/all",
  async () => {
    const res = await CardService.getAll();
    return res.data;
  }
);



const cardSlice = createSlice({
  name: "card",
  initialState,
  extraReducers: {
    [getCards.fulfilled]: (state, action) => {
        return action.payload
    },

  },
});

const { reducer } = cardSlice;
export default reducer;