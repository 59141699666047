import axios from "axios"
import { formatDate } from "../utils/utils";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = ()=>{
    return axios.get(`print-requests`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data)=>{
        // console.log(data.data);
        return data;
    })
}

const getAllAdmin = (interval = [new Date(), new Date()]) => {
    let url = `admin/print_requests`
    
    if (interval) {
        const startDate = formatDate(interval[0])
        const endDate = formatDate(interval[1])
        url = `admin/print_requests?startDate=${startDate}&endDate=${endDate}`
    }
    return axios.get(url, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data) => {
        return data;
    })
}


const PrintRequestService = {
    getAll,
    getAllAdmin
}

export default PrintRequestService;