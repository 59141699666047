/* eslint-disable no-unused-vars */
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import usePasswordVisibility from "../../hook/usePasswordVisibility";
import "./../styles/LoginForm.css";
import "./../styles/ProfilForm.css";
import "react-phone-number-input/style.css";
import { useRef, useState } from "react";
import useLanguage from "../../hook/useLanguage";
import useForm from "../../hook/useForm";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isEmail } from "../../utils/utils";
import { parsePhoneNumber } from "react-phone-number-input";
import "react-image-crop/dist/ReactCrop.css";
import CropImageModal from "../CropImageModal";
import { useDispatch } from "react-redux";
import { updateUser } from "../../redux/slices/userSlice";
import { toast } from "react-toastify";

// type Schema = z.infer<updateProfileSchema>;

const ProfilForm = ({ user }) => {
  const navigate = useNavigate();
  const [language, t, handleChangeLanguage] = useLanguage();
  const [passwordVisibility, handleTogglePasswordVisibility] =
    usePasswordVisibility(false);

  const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
    {
      ...user,
      phone: user.phone
        ? parsePhoneNumber(user.phone, user.country)?.number
        : "",
      password: null,
    },
    (name, value) => {
      if (name === "last_name") {
        if (value.length < 3) return t("incorrect last name");
      }
      if (name === "first_name") {
        if (value.length < 3) return t("incorrect first name");
      }
      if (name === "email") {
        if (value && value.length > 0 && !isEmail(value))
          return t("incorrect email");
      }
      if (name === "city") {
        if (value.length < 2) return t("incorrect city");
      }
      if (name === "address") {
        if (value.length < 4) return t("incorrect address");
      }
      if (name === "phone") {
        if (!isValidPhoneNumber(value)) return t("incorrect phone");
      }
      if (name === "password" && formValues.password) {
        if (value.length < 7) return t("incorrect password");
      }
      return null;
    }
  );

  const [isCropImage, setIsCropImage] = useState(false);
  const [cropData, setCropData] = useState({ save: null, load: null });
  const [image, setImage] = useState(null);
  let userImage = user.image;
  let splitImage;

  const avatarRef = useRef();
  const submitRef = useRef();
  const formRef = useRef();

  const submitData = useRef();

  const dispatch = useDispatch();

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (submitData.current) {
      submitData.current.setAttribute("disabled", "true");
      submitData.current.innerHTML =
        '<div class="spinner-grow spinner-grow-sm" role="status"><span class="visually-hidden">Loading...</span></div>';
      const datas = new FormData();
      datas.append("image", formRef.current[0].files[0]);
      datas.append("email", formValues.email);
      datas.append("address", formValues.address);
      datas.append("city", formValues.city);
      datas.append("password", formValues.password);

      if (user.phone) {
        try {
          const parsedPhone = parsePhoneNumber(user.phone);

          if (parsedPhone && parsedPhone.country) {
            datas.append("country", parsedPhone.country);
          } else {
            datas.append("country", "CM");
          }
        } catch (error) {
          console.log(error);
        }
      }
      dispatch(updateUser({ data: datas, id: formValues.id }))
        .then((response) => {
          console.log(response);
          if (response.payload !== undefined && response.payload.code === 200) {
            toast.success("Votre profil a été mis à jour.");
          }
          if (response.payload !== undefined && response.payload.code === 409) {
            toast.error(response.payload.errors);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          submitData.current.removeAttribute("disabled");
          submitData.current.innerHTML = "Enregistrer";
        });
    }
  };

  const handleChangeAvatar = (e) => {
    avatarRef.current.click();
  };

  const resizeImage = (file, maxWidth, maxHeight, callback) => {
    console.log(file);
    var img = new Image();

    img.onload = function () {
      var width = img.width;
      var height = img.height;

      // Calculate the new image dimensions
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Create a canvas and draw the resized image onto it
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Get the data URL of the resized image
      var dataUrl = canvas.toDataURL("image/jpeg");
      callback(dataUrl);
    };

    // Load the input file as a data URL
    var reader = new FileReader();
    reader.onload = function (event) {
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const handleInputChange = (e) => {
    resizeImage(e.target.files[0], 1500, 500, (url) => {
      setImage(url);
      setIsCropImage(true);
    });
  };

  if (user.image) {
    splitImage = userImage.split(":");
  }

  return (
    <div className="LoginForm ProfilForm col-12 col-md-9">
      <form ref={formRef} id="Updateform" className="p-2 m-2 py-4">
        <div className="d-flex align-items-center justify-content-center mb-3">
          <img
            onClick={handleChangeAvatar}
            src={
              cropData.save?.base64 ??
              (Array.isArray(splitImage) && splitImage.length > 1
                ? user.image
                : undefined) ??
              "../../images/avatar.jpg"
            }
            className="avatarIMG"
            alt=""
          />
          <i onClick={handleChangeAvatar} className="fa fa-camera"></i>
          <input
            onChange={handleInputChange}
            ref={avatarRef}
            type="file"
            accept="image/png,image/jpg,image/jpeg"
            hidden
          />
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="row">
              <div className="col-12 col-lg-6">
                <div class="mb-3">
                  <input
                    disabled
                    value={formValues.last_name}
                    onChange={handleChange}
                    type="text"
                    name="last_name"
                    class="form-control"
                    placeholder={t("Last name")}
                  />
                  {errors.last_name && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {t(errors.last_name)}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div class="mb-3">
                  <input
                    disabled
                    value={formValues.first_name}
                    onChange={handleChange}
                    type="text"
                    class="form-control"
                    name="first_name"
                    placeholder={t("First name")}
                  />
                  {errors.first_name && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {t(errors.first_name)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div class="mb-3">
              <input
                value={formValues.email}
                type="email"
                onChange={handleChange}
                class="form-control"
                name="email"
                placeholder={t("Email")}
              />
              {errors.email && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {t(errors.email)}
                </span>
              )}
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div class="mb-3">
                  <input
                    value={formValues.city}
                    onChange={handleChange}
                    type="text"
                    class="form-control"
                    name="city"
                    placeholder={t("City")}
                  />
                  {errors.city && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {t(errors.city)}
                    </span>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div class="mb-3">
                  <input
                    value={formValues.address}
                    onChange={handleChange}
                    type="text"
                    class="form-control"
                    name="address"
                    placeholder={t("Address")}
                  />
                  {errors.address && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {t(errors.address)}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div class="my-3">
              <PhoneInput
                name="phone"
                defaultCountry={formValues.country}
                placeholder={t("Phone number")}
                value={formValues.phone}
                onChange={(value) => {
                  handleChange(null, "phone", value);
                }}
              />
              {errors.phone && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {t(errors.phone)}
                </span>
              )}
            </div>

            <div className="input-group">
              <input
                autocomplete="new-password"
                value={formValues.password}
                onChange={handleChange}
                type={passwordVisibility ? "text" : "password"}
                className="form-control"
                name="password"
                placeholder={t("Password")}
              />
              <span class="input-group-text" id="togglePasswordVisibility">
                <i
                  onClick={handleTogglePasswordVisibility}
                  className="fa fa-eye"
                ></i>
              </span>
            </div>
            {errors.password && (
              <span className="form-error mb-3">
                <i
                  style={{ fontSize: ".8em" }}
                  className="fa fa-info-circle"
                ></i>{" "}
                {t(errors.password)}
              </span>
            )}
          </div>
        </div>
        <button
          onClick={handleUpdateUser}
          type="submit"
          ref={submitData}
          className="btn btn-primary my-3 mt-4 mx-auto col-12 col-md-6"
        >
          {t("Enregistrer")}
        </button>
      </form>

      {isCropImage && (
        <CropImageModal
          open={isCropImage}
          image={image}
          onSave={(data) => {
            setIsCropImage(false);
            setCropData((value) => {
              return { ...value, save: data };
            });
          }}
          onClose={() => setIsCropImage(false)}
        />
      )}
    </div>
  );
};

export default ProfilForm;
