/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import "./../../styles/LoginForm.css";
import "./../../styles/ProfilForm.css";
import "react-phone-number-input/style.css";
import { useRef, useState } from "react";
import useLanguage from "../../../hook/useLanguage";
import useForm from "../../../hook/useForm";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { formatPrice } from "../../../utils/utils";
import { addGame } from "../../../redux/slices/gameSlice";

const AddGameForm = ({ onBack }) => {
  const navigate = useNavigate();
  const [language, t, handleChangeLanguage] = useLanguage();

  const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
    { name: "", image: null, quantity: "", price: "" },
    (name, value) => {
      if (name === "stadium") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team1Name") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team2Name") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team1Logo") {
        if (!image) return "you must provide item image";
      }
      if (name === "team2Logo") {
        if (!image) return "you must provide item image";
      }
      if (name === "startAt") {
        if (Number(value) < 1) return "quantity must be greather than 1";
      }
      if (name === "endAt") {
        if (Number(value) < 500)
          return `price must be greather than ${formatPrice(500)}`;
      }
      return null;
    }
  );

  const validateForm = () => {
    let errors = {
      stadium: "",
      team1Name: "",
      team2Name: "",
      price: "",
    };

    let isValid = true;

    if (!formValues.stadium) {
      errors.stadium = "stadium est requis";
      isValid = false;
    }

    // if (!formValues.name) {
    //   errors.team1Name = "team1Name est requis";
    //   isValid = false;
    // }

    // if (!formValues.team2Name) {
    //   errors.team2Name = "Price est requis";
    //   isValid = false;
    // }

    setErrors(errors);

    return isValid;
  };

  const [image, setImage] = useState(null);

  const avatarRef = useRef();
  const formRef = useRef();
  const submitBtnRef = useRef();

  const dispatch = useDispatch();

  //   const handleChangeImage = (e) => {
  //     avatarRef.current.click();
  //   };

  //   const handleImageChange = (e) => {
  //     const fileReader = new FileReader();
  //     fileReader.onloadend = (event) => {
  //       setImage(fileReader.result);
  //     };

  //     fileReader.readAsDataURL(e.target.files[0]);
  //   };
  const inputImageRef = useRef([null, null]);

  const [previewImageSrc, setPreviewImageSrc] = useState([null, null]);

  const handleInputImageChange = (e, i) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      let previewImageSrcData = previewImageSrc.slice();
      previewImageSrcData[i] = reader.result;
      setPreviewImageSrc(previewImageSrcData);
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (validateForm()) {
      e.target.setAttribute("disabled", "true");
      e.target.innerHTML =
        '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';

      const formData = new FormData(formRef.current);
      let data = {
        stadium: formData.get("stadium"),
        team1Name: formData.get("team1.name"),
        team1Logo: formData.get("team1.logo"),
        team2Name: formData.get("team2.name"),
        team2Logo: formData.get("team2.logo"),
        startAt: formData.get("startAt"),
        endAt: formData.get("endAt"),
      };

      console.log("data", data);

      dispatch(
        addGame({
          ...data,
        })
      )
        .unwrap()
        .then((data) => {
          e.target.removeAttribute("disabled");
          e.target.innerHTML = "ENREGISTRER";
          formRef.current.reset();
          setPreviewImageSrc([null, null]);
          toast.success("Le match a été crée.");
          onBack();
        })
        .catch((err) => {
          console.log(err);
          toast.error("Veuillez remplir correctement le formulaire");
        })
        .finally(() => {
          e.target.removeAttribute("disabled");
          e.target.innerHTML = "ENREGISTRER";
        });
    }
  };

  return (
    <>
      <h5 className="d-flex fw-bold text-light gap-2">
        <i
          onClick={onBack}
          className="backButton fa fa-arrow-left-long fa-1x"
        ></i>{" "}
        Ajouter un match
      </h5>
      <div className="LoginForm col-12 col-md-9">
        <form ref={formRef} className="p-2 m-2 py-4">
          <div className="form-floating mb-3 pt-2">
            <input
              required
              name="stadium"
              type="text"
              className="form-control"
              id="floatingInput"
            />
            <label htmlfor="floatingInput">Nom du stade</label>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
            <div className="form-floating w-100 mb-3 pt-2">
              <input
                required
                name="team1.name"
                type="text"
                className="form-control"
                id="floatingInput"
              />
              <label htmlfor="floatingInput">Nom de l'équipe 1</label>
            </div>
            <div className="form-floating mb-3 pt-2 w-100">
              <input
                required
                name="team2.name"
                type="text"
                className="form-control"
                id="floatingInput"
              />
              <label htmlfor="floatingInput">Nom de l'équipe 2</label>
            </div>
          </div>
          <div className="row">
            <div className="form-floating mb-3 pt-2 col-12 col-md-6">
              <input
                onLoad={(e) => {
                  e.target.valueAsDate = new Date();
                }}
                required
                name="startAt"
                type="datetime-local"
                className="form-control"
                id="floatingInput"
              />
              <label htmlfor="floatingInput">Date de début</label>
            </div>
            <div className="form-floating mb-3 pt-2 col-12 col-md-6">
              <input
                onLoad={(e) => {
                  e.target.valueAsDate = new Date();
                }}
                required
                name="endAt"
                type="datetime-local"
                className="form-control"
                id="floatingInput"
              />
              <label htmlfor="floatingInput">Date de fin</label>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
            <div className="form-floating py-1 w-100">
              <div
                className="d-flex justify-content-center align-items-center imageContainer"
                style={{ height: "100px" }}
              >
                <div
                  onClick={() => inputImageRef.current[0].click()}
                  title="Ajouter une image"
                  className="w-50 d-flex flex-column justify-content-center align-items-center h-100"
                >
                  {previewImageSrc[0] === null ? (
                    <>
                      <i className="fa-solid fa-image fa-2x"></i>
                      <span>Logo Equipe 1</span>
                    </>
                  ) : (
                    <>
                      <img
                        className="w-75"
                        src={previewImageSrc[0]}
                        alt="preview"
                      />
                    </>
                  )}
                </div>
              </div>
              <input
                name="team1.logo"
                accept="image/*"
                onChange={(e) => handleInputImageChange(e, 0)}
                ref={(el) => (inputImageRef.current[0] = el)}
                type="file"
                hidden
              />
            </div>
            <div className="form-floating w-100 py-1">
              <div className="d-flex justify-content-center align-items-center imageContainer mt-4">
                <div
                  onClick={() => inputImageRef.current[1].click()}
                  title="Ajouter une image"
                  className="w-50 d-flex flex-column justify-content-center align-items-center h-100"
                >
                  {previewImageSrc[1] === null ? (
                    <>
                      <i className="fa-solid fa-image fa-2x"></i>
                      <span>Logo Equipe 2</span>
                    </>
                  ) : (
                    <>
                      <img
                        className="w-75"
                        src={previewImageSrc[1]}
                        alt="preview"
                      />
                    </>
                  )}
                </div>
              </div>
              <input
                name="team2.logo"
                accept="image/*"
                onChange={(e) => handleInputImageChange(e, 1)}
                ref={(el) => (inputImageRef.current[1] = el)}
                type="file"
                hidden
              />
            </div>
          </div>

          <button
            ref={submitBtnRef}
            onClick={handleCreate}
            type="submit"
            className="btn btn-primary my-3 mt-4 mx-auto col-12"
          >
            {t("Ajouter")}
          </button>
        </form>
      </div>
    </>
  );
};

export default AddGameForm;
