/* eslint-disable no-unused-vars */
import { useState } from "react";
// import { formatPhoneNumber } from 'react-phone-number-input';
import { parsePhoneNumber } from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
// import { NavLink } from 'react-router-dom';
import useForm from "../../hook/useForm";
import useLanguage from "../../hook/useLanguage";
// import usePasswordVisibility from '../../hook/usePasswordVisibility';
import { UserService } from "../../services/UserService";
import { authFormValidator, getHostName, isEmail } from "../../utils/utils";
import Alert from "../Alert";
import "./../styles/LoginForm.css";

const ForgotPasswordForm = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const [message, setMessage] = useState(null);
  const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
    { id: "" },
    authFormValidator
  );

  const validate = () => {
    let errors = {
      id: "",
    };

    let isValid = true;

    if (!formValues.id) {
      errors.id = "ID est requis";
      isValid = false;
    }

    setErrors(errors);

    return isValid;
  };

  const __handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const isSubmittable = handleSubmit(e);
    if (validate()) {
      e.target.setAttribute("disabled", "true");
      e.target.innerHTML =
        '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';
      let data;
      if (isEmail(formValues.id)) {
        data = { phone: formValues.id };
      } else {
        data = { phone: parsePhoneNumber(formValues.id, "CM").number };
      }

      data = {
        ...data,
        baseLink: getHostName() + "/recover-password",
      };
      console.log(data);
      UserService.askNewPasswordLink(data)
        .then((json) => {
          if (json.data.errors) {
            setErrors(json.data.errors);
          } else {
            setMessage(
              t(
                "The reset link has been sent to your email/phone please verify it."
              )
            );
          }
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.errors) {
            setErrors(data.errors);
          }
        })
        .finally(() => {
          e.target.removeAttribute("disabled");
          e.target.innerHTML = t("Login");
        });
    } else {
      e.target.removeAttribute("disabled");
      e.target.innerHTML = t("Login");
    }
  };

  return (
    <div className="LoginForm shadow">
      <form className="p-2 m-2 py-4">
        <h3 className="mb-4">{t("Reset your password")}</h3>
        <Alert message={message} type={"success"} />
        {errors.id && (
          <div className="mb-3">
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
              {t(errors.id)}
            </span>
          </div>
        )}
        {errors.length > 0 && (
          <div className="mb-3">
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
              {t(errors[0])}
            </span>
          </div>
        )}
        {!message && (
          <>
            <div className="mb-3">
              <input
                value={formValues.id}
                onChange={handleChange}
                type="text"
                className="form-control"
                name="id"
                placeholder={t("Telephone or e-mail address").toLowerCase()}
              />
            </div>
            <button
              onClick={__handleSubmit}
              type="submit"
              className="btn btn-primary my-3 mt-4 w-100"
            >
              {t("reset")}
            </button>
          </>
        )}
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
