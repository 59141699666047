/* eslint-disable no-unused-vars */
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import AppContainer from "../components/AppContainer";
import RegisterForm from "../components/form/RegisterForm";
import useLanguage from "../hook/useLanguage";

import "./../styles/LoginPage.css";
import useAuthToken from "../hook/useAuthToken";

const RegisterPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const authToken = useAuthToken();
 /*  if (!authToken) {
    window.location = '/'
  } */

  return (
    <AppContainer>
      <RegisterForm />

      <div className="d-flex mt-3 justify-content-start align-items-center gap-3">
        <div className="text-light fw-bold">
          {t("Already have an account?")}
        </div>
        <NavLink className="btn btn-primary" to="/login">
          {t("Login")}
        </NavLink>
      </div>
    </AppContainer>
  );
};

export default RegisterPage;
