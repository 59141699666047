/* eslint-disable no-unused-vars */
import PhoneInput from "react-phone-number-input";
import { useNavigate } from "react-router-dom";
import usePasswordVisibility from "../../hook/usePasswordVisibility";
import "./../styles/LoginForm.css";
import "react-phone-number-input/style.css";
import { useEffect, useRef, useState } from "react";
import useLanguage from "../../hook/useLanguage";
import useForm from "../../hook/useForm";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isEmail } from "../../utils/utils";
import { parsePhoneNumber } from "react-phone-number-input";
import { UserService } from "../../services/UserService";
import { toast } from "react-toastify";

const RegisterForm = () => {
  const navigate = useNavigate();

  const [language, t, handleChangeLanguage] = useLanguage();
  const [passwordVisibility, handleTogglePasswordVisibility] =
    usePasswordVisibility(false);

  const [
    formValues,
    errors,
    setErrors,
    handleChange,
    handleFileChange,
    handleSubmit,
  ] = useForm(
    {
      last_name: "",
      first_name: "",
      email: "",
      city: "",
      address: "",
      phone: "",
      password: "",
      country: "cameroon",
      image: null, // Added image field for file upload
    },
    (name, value) => {
      if (name === "last_name" && value.length < 3)
        return "incorrect last name";
      if (name === "first_name" && value.length < 3)
        return "incorrect first name";
      if (name === "city" && value.length < 2) return "incorrect city";
      if (name === "address" && value.length < 2) return "incorrect address";
      if (name === "phone" && !isValidPhoneNumber(value))
        return "incorrect phone";
      if (name === "password" && value.length < 6) return "incorrect password";
      return null;
    }
  );

  const [isOtpStep, setIsOtpStep] = useState(
    localStorage.getItem("otpConfirmation") !== null
  );

  const [image, setImage] = useState(null);

  const avatarRef = useRef();
  const submitButtonRef = useRef();

  const resizeImage = (file, maxWidth, maxHeight, callback) => {
    console.log(file);
    var img = new Image();

    img.onload = function () {
      var width = img.width;
      var height = img.height;

      // Calculate the new image dimensions
      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else {
        if (height > maxHeight) {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      // Create a canvas and draw the resized image onto it
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Get the data URL of the resized image
      var dataUrl = canvas.toDataURL("image/jpeg");
      callback(dataUrl);
    };

    // Load the input file as a data URL
    var reader = new FileReader();
    reader.onload = function (event) {
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };

  const formRef = useRef();

  const validateForm = () => {
    let errors = {
      last_name: "",
      first_name: "",
      email: "",
      city: "",
      address: "",
      phone: "",
      password: "",
    };

    let isValid = true;

    if (!formValues.last_name) {
      errors.last_name = "Last name est requis";
      isValid = false;
    }

    if (!formValues.first_name) {
      errors.first_name = "First name est requis";
      isValid = false;
    }

    if (!formValues.address) {
      errors.address = "Address est requis";
      isValid = false;
    }

    if (!formValues.phone) {
      errors.phone = "Phone est requis";
      isValid = false;
    }

    if (!formValues.password) {
      errors.password = "Password est requis";
      isValid = false;
    }

    setErrors(errors);

    return isValid;
  };

  useEffect(() => {
    if (isOtpStep) {
      navigate("/verify-otp");
    }
  }, [isOtpStep, navigate]);

  const __handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const isSubmittable = handleSubmit(e);

    console.log("Handle submit", handleSubmit(e));

    if (submitButtonRef.current) {
      if (validateForm()) {
        submitButtonRef.current.setAttribute("disabled", "true");
        submitButtonRef.current.innerHTML =
          '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';

        // const data = {
        //   ...formValues,
        //   image: formRef.current[0].files[0],
        // };

        const datas = new FormData();
        datas.append("image", formRef.current[0].files[0]);
        datas.append("email", formValues.email);
        datas.append("address", formValues.address);
        datas.append("city", formValues.city);
        datas.append("password", formValues.password);
        datas.append("first_name", formValues.password);
        datas.append("last_name", formValues.password);
        console.log("====================================");
        console.log(formValues);
        console.log("====================================");

        // console.log("data", datas);
        // console.log("formValue", formValues);
        UserService.signup({ data: formValues })
          .then((json) => {
            toast.success(
              "Inscription réussie, veuillez vérifier votre boite mail ou votre téléphone.",
              {
                autoClose: false,
              }
            );
            // set the jwt token in local storage
            localStorage.setItem("authToken", json.data.token);
            localStorage.setItem("authTokenDate", new Date());
            localStorage.setItem("otpConfirmation", "true");
            localStorage.setItem("otpPhone", formValues.phone);
            localStorage.setItem("otpMail", formValues.email);
            setIsOtpStep(true);
          })
          .catch((err) => {
            const data = err.response.data;
            if (data.errors) {
              setErrors((state) => {
                return { ...state, ...data.errors };
              });
              toast.error(
                "Des erreurs ont été rencontrées, veuillez corriger le formulaire et réessayer."
              );
            }
          })
          .finally(() => {
            submitButtonRef.current.removeAttribute("disabled");
            submitButtonRef.current.innerHTML = t("Register");
          });
      } else {
        console.log(formValues);
      }
    }
  };

  return (
    <div className="LoginForm shadow">
      <form className="p-2 m-2 py-4" ref={formRef}>
        <h3 className="mb-4">{t("Registration")}</h3>

        {/* profile picture */}

        <div className="mb-2 text-center">
          <label
            htmlFor="fileInput"
            className="d-inline-block position-relative"
          >
            <img
              src={
                formValues.image
                  ? URL.createObjectURL(formValues.image)
                  : `${process.env.PUBLIC_URL + "/assets/avatar.png"}`
              }
              alt=""
              name="image"
              className="rounded-circle border"
              style={{
                width: "150px",
                height: "150px",
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
            <div className="position-absolute bottom-0 end-0 p-2">
              <i className="bi bi-camera-fill fs-4"></i>
            </div>
          </label>
          <input
            type="file"
            name="image"
            id="fileInput"
            onChange={handleFileChange}
            accept="image/*"
            className="form-control d-none"
          />
        </div>

        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={formValues.last_name}
                onChange={handleChange}
                type="text"
                name="last_name"
                className="form-control"
                placeholder={t("Last name")}
              />
              {errors.last_name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {t(errors.last_name)}
                </span>
              )}
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={formValues.first_name}
                onChange={handleChange}
                type="text"
                className="form-control"
                name="first_name"
                placeholder={t("First name")}
              />
              {errors.first_name && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {t(errors.first_name)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <input
            value={formValues.email}
            type="email"
            onChange={handleChange}
            className="form-control"
            name="email"
            placeholder={t("Email")}
          />
          {errors.email && (
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
              {t(errors.email)}
            </span>
          )}
        </div>
        <div className="mb-3">
          <PhoneInput
            name="phone"
            defaultCountry="CM"
            placeholder={t("Phone number")}
            value={formValues.phone}
            onChange={(value) => {
              handleChange(null, "phone", value);
            }}
          />
          {errors.phone && (
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
              {t(errors.phone)}
            </span>
          )}
        </div>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={formValues.city}
                onChange={handleChange}
                type="text"
                className="form-control"
                name="city"
                placeholder={t("City")}
              />
              {errors.city && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {t(errors.city)}
                </span>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="mb-3">
              <input
                value={formValues.address}
                onChange={handleChange}
                type="text"
                className="form-control"
                name="address"
                placeholder={t("Address")}
              />
              {errors.address && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {t(errors.address)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="input-group d-flex">
          <input
            value={formValues.password}
            onChange={handleChange}
            type={passwordVisibility ? "text" : "password"}
            className="form-control"
            name="password"
            placeholder={t("Password")}
          />
          <span className="input-group-text" id="togglePasswordVisibility">
            <i
              onClick={handleTogglePasswordVisibility}
              className="fa fa-eye"
            ></i>
          </span>
        </div>
        {errors.password && (
          <span className="form-error mb-3">
            <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
            {t(errors.password)}
          </span>
        )}
        <button
          onClick={__handleSubmit}
          type="submit"
          ref={submitButtonRef}
          className="btn btn-primary my-3 mt-4 w-100"
        >
          {t("Register")}
        </button>
      </form>
    </div>
  );
};

export default RegisterForm;
