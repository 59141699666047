import { useState } from "react";
import { useEffect } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { getCartsAdmin } from "../../redux/slices/cartSlice";
import { getCommandsAdmin, markedAsDelivered } from "../../redux/slices/commandSlice";
import { getItems } from "../../redux/slices/itemSlice";
import { getPaymentsAdmin } from "../../redux/slices/paymentSlice";
import { formatPrice, getCommandCart, getCommandPayment, getModelById, parsePaymentMode, parseReadableDate } from "../../utils/utils";
import CircularLoader from "../loader/CircularLoader";
import ImageCarousel from '../../components/history/ImageCarousel';
import { Modal } from "../Modal";
import { toast } from "react-toastify";
import { getMembers } from "../../redux/slices/memberSlice";
import ImageViewer from "../ImageViewer";
import { PAYMENT_STATUS } from "../../utils/constants";

const ShopCommandList = () => {

    const userData = useSelector((state) => state.user)
    const itemData = useSelector((state) => state.item)
    const cartData = useSelector((state) => state.cart)
    const paymentData = useSelector((state) => state.payment)
    const commandData = useSelector((state) => state.command)
    const memberData = useSelector((state) => state.member)
    const dispatch = useDispatch()

    const [openCarousel, setOpenCarousel] = useState(false)

    const [imageViewer, setImageViewer] = useState(null);

    useEffect(() => {
        if (!paymentData) dispatch(getPaymentsAdmin({ reset: false, interval: null }))
        if (!itemData) dispatch(getItems({ reset: false, interval: null }))
        if (!cartData) dispatch(getCartsAdmin({ reset: false, interval: null }))
        if (!commandData) dispatch(getCommandsAdmin({ reset: false, interval: null }))
        if (!memberData) dispatch(getMembers({ reset: false, interval: null }))
    }, [cartData, commandData, dispatch, itemData, memberData, paymentData])

    const handleMarkAsDelivered = (e, command) => {
        setConfirm({
            title: 'Confirmation',
            message: `voulez-vous vraiment marqué cette commande comme livré ? (cette action est irréversible)`,
            callback: function (ev) {
                ev.preventDefault()
                ev.stopPropagation()
                ev.target.setAttribute('disabled', 'true')
                ev.target.innerHTML = "<div className='spinner-grow spinner-grow-sm' role='status'></div>";

                // start voting for this player
                setConfirm(null)
                e.target.innerHTML = "<div className='spinner-grow spinner-grow-sm' role='status'></div>"
                e.target.setAttribute('disabled', true)
                dispatch(markedAsDelivered({ ...command }))
                    .then(() => {
                        toast.success('Cette commande a été marqué comme livré.')
                    })
                    .catch((err) => { console.log(err) })
                    .finally(() => {
                        e.target.innerHTML = ''
                        e.target.innerHTML = '<i className="fa fa-shopping-cart"></i>'
                        e.target.removeAttribute('disabled')
                    })
            }
        })
    }

    const commandDataColumns = [
        {
            name: 'Profile',
            selector: (row) => {
                const user = getModelById(memberData, row.user_id)
                if (user.image) {
                    return <img onClick={() => setImageViewer(user.image)} src={user.image} className='w-50 p-2' style={{ borderRadius: '50%' }} alt='' />
                }
                return <small className="text-muted small" style={{ userSelect: 'none' }}>non renseignée</small>
            }
        },
        {
            name: 'Date',
            selector: (row) => {
                return parseReadableDate(row.created_at)
            },
            sortable: true,
            sortFunction: (a, b) => {
                let aDate = new Date(a.created_at)
                let bDate = new Date(b.created_at)
                return aDate - bDate;
            }
        },
        {
            name: 'Nom(s)',
            selector: (row) => {
                const user = getModelById(memberData, row.user_id)
                return <>{user.last_name} <br /> {user.first_name}</>
            },
            sortable: true,
        },
        {
            name: "Mode de paiement",
            selector: (row) => {
                const payment = getCommandPayment(row.id, paymentData)
                return <div>{parsePaymentMode(payment.payment_method)}</div>
            }
        },
        {
            name: "Articles",
            selector: (row) => {
                let carts = getCommandCart(cartData, row.id);
                let items = carts.map((cart) => getModelById(itemData, cart.item_id));
                let item_name = items.map((item) => item.name).join(',');

                return <div className="article_list_hover" style={{ cursor: 'pointer' }} onClick={() => setOpenCarousel([items, carts])}> {item_name} </div>;
            },
        },
        {
            name: "Quantité",
            selector: (row) => {
                let carts = getCommandCart(cartData, row.id);
                return carts.reduce((prev, next) => {
                    return prev + next.quantity
                }, 0);
            },
        },
        {
            name: "Prix Total",
            selector: (row) => {
                return formatPrice(row.price);
            },
        },
        {
            name: "Statut",
            selector: (row) => {
                let statut;
                let color;
                const payment = getCommandPayment(row.id, paymentData)
                if (payment.status === PAYMENT_STATUS.PROGRESS) {
                    statut = "en cours";
                    color = "#008A20";
                } else if (payment.status === "rejected") {
                    statut = "annulé";
                    color = "red";
                } else if (payment.status === PAYMENT_STATUS.RECEIVED) {
                    statut = "complété";
                    color = "#00eea1";
                }

                return <div className="error" style={{ color: color, fontWeight: "bold" }}>{statut}</div>
            },
        },
        {
            name: "état".toLocaleUpperCase(),
            selector: (row) => {
                const delivered = parseInt(row.delivered)===1
                if (delivered) {
                    return <div>livré</div>
                }
                return <div>non livré</div>
            }
        },
        {
            name: "Actions",
            selector: row => {
                let carts = getCommandCart(cartData, row.id);
                let items = carts.map((cart) => getModelById(itemData, cart.item_id));
                const delivered = parseInt(row.delivered)===1;
                return <div className="d-flex justify-content-around w-100">
                    <button onClick={() => { setOpenCarousel([items, carts]) }} title="visualisez voz articles" style={{ color: "#f5f5f5", backgroundColor: "#00a1ff" }} className="actionBtn"><i className="fa fa-eye" aria-hidden="true"></i></button>

                    {!delivered && parseInt(userData.admin) === 1 && parseInt(userData.super_admin) === 1 && <button title="marqué comme livré" onClick={(e) => handleMarkAsDelivered(e, row)} style={{ color: "#f5f5f5", backgroundColor: "#00eea1" }} className="actionBtn mx-1"> <i className="fa fa-shopping-cart" aria-hidden="true"></i></button>}
                </div>
            }
        }
    ];

    const [confirm, setConfirm] = useState(null)


    return <div className="ShopCommandList">
        {commandData && cartData !== null && itemData !== null && paymentData !== null && memberData !== null  ?
            <DataTable className='PaymentHistory mt-3'
                columns={commandDataColumns}
                data={commandData}
                striped
                pagination
                paginationPerPage={5}
                paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            /> : <CircularLoader />}
        {openCarousel && <div className={'ImageViewer d-flex align-items-center justify-content-center'}>
            <div onClick={() => { setOpenCarousel(null) }} className='cross d-flex justify-content-center shadow align-items-center'>
                <i className="fa fa-close" aria-hidden="true"></i>
            </div>
            <ImageCarousel items={openCarousel} />

        </div>}
        {confirm &&
            <Modal onClose={(e) => { setConfirm(null) }} open={confirm ?? false}
                title={<div className="text-uppercase" style={{ color: '#e89f29' }}><i className="fas fa-exclamation-circle fa-1x"></i> {confirm.title}</div>}>
                {!confirm.onConfirm && <div className="d-flex flex-column justify-content-between p-3">
                    <div>
                        {confirm.message}
                    </div>
                    <div className="d-flex justify-content-end w-100 mt-3">
                        <button style={{ background: '#e89f29 !important' }} className='downloadBtn warning text-uppercase' onClick={(e) => confirm.callback(e)}>Confirmer</button>
                    </div>
                </div>}
            </Modal>}
        {imageViewer &&
            <ImageViewer image={imageViewer} onClose={() => { setImageViewer(null) }} open={imageViewer !== null} />
        }
    </div>

}

export default ShopCommandList;