import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import PaymentService from "../../services/PaymentService.js";
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const initialState = null;

export const getPayments = createAsyncThunk(
    "payments/all",
    async () => {
        const res = await PaymentService.getAll();
        return res.data;
    }
);
export const getPaymentsAdmin = createAsyncThunk(
    "payments/all",
    async ({ reset, interval }) => {
        if (reset) {
            return initialState
        }
        const res = await PaymentService.getAllAdmin(interval);
        //console.log(res.data);
        return res.data;
    }
);

export const getPaymentsItems = createAsyncThunk(
    "payments-items/all",
    async () => {
        const res = await PaymentService.getPayItems();
        return res.data;
    }
);

export const handlePayment = createAsyncThunk(
    "payments/handle",
    async (payment, { rejectWithValue }) => {
        try {
            const res = await PaymentService.handle(payment)
            return res;
        } catch (err) {
            return rejectWithValue({
                errors: err.response.data,
                status: err.response.status
            })
        }
    }
);


export const updatePayment = createAsyncThunk(
    "payments/update",
    async (payment, { rejectWithValue }) => {
        try {            
            return payment;
        } catch (err) {
            console.log(err);
            return rejectWithValue({
                errors: err
            })
        }
    }
);


const paymentSlice = createSlice({
    name: "payment",
    initialState,
    extraReducers: {
        [getPayments.fulfilled]: (state, action) => {
            return action.payload
        },
        [getPaymentsAdmin.fulfilled]: (state, action) => {
            return action.payload
        },
        [updatePayment.fulfilled]: (state, action) => {
            const payment = action.payload.data.payment;
            if (state === null) {
                return [payment]
            }
            return state.map(item => {
                if (parseInt(item.id) === parseInt(payment.id)) return payment;
                return item;
            })
        },
        [handlePayment.fulfilled]: (state, action) => {
            if (state === null)
                return [action.payload.data.payment]
            state.push(action.payload.data.payment)
            return state
        },
    },
});

const { reducer } = paymentSlice;
export default reducer;