/* eslint-disable no-unused-vars */
import { useState } from "react";
// import { formatPhoneNumber } from 'react-phone-number-input';
// import { parsePhoneNumber } from 'react-phone-number-input';
// import { isValidPhoneNumber } from 'react-phone-number-input';
import {
  // NavLink,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import useForm from "../../hook/useForm";
import useLanguage from "../../hook/useLanguage";
// import usePasswordVisibility from '../../hook/usePasswordVisibility';
import { UserService } from "../../services/UserService";
// import { authFormValidator, getHostName } from '../../utils/utils';
import Alert from "../Alert";
import "./../styles/LoginForm.css";

const RecoverPasswordForm = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const resetPasswordToken = searchParams.get("reset_token");
  const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
    { token: resetPasswordToken, password: "", __password: "" },
    (name, value) => {
      if (name === "password") {
        if (value.length < 6) return "password length must be greather than 6";
      }
      if (name === "__password") {
        if (formValues.password !== value)
          return "the passwords are different !";
      }
      return null;
    }
  );

  const __handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const isSubmittable = handleSubmit(e);
    if (isSubmittable) {
      e.target.setAttribute("disabled", "true");
      e.target.innerHTML =
        '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';

      UserService.changePassword(formValues)
        .then((json) => {
          if (json.data.errors) {
            setErrors(json.data.errors);
          } else {
            setMessage(
              t(
                "Your password has been successfully changed ! we will redirect you to the login page..."
              )
            );
            setTimeout(() => {
              navigate("/login");
            }, 2500);
          }
        })
        .catch((err) => {
          const data = err.response.data;
          if (data.errors) {
            setErrors(data.errors);
          }
        })
        .finally(() => {
          e.target.removeAttribute("disabled");
          e.target.innerHTML = t("Login");
        });
    } else {
      e.target.removeAttribute("disabled");
      e.target.innerHTML = t("reset");
    }
  };

  return (
    <div className="LoginForm shadow">
      <form className="p-2 m-2 py-4">
        <h3 className="mb-4">{t("Reset your password")}</h3>
        <Alert message={message} type={"success"} />

        {errors.length > 0 && (
          <div className="mb-3">
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
              {t(errors[0])}
            </span>
          </div>
        )}

        <div className="mb-3">
          <input
            value={formValues.password}
            onChange={handleChange}
            type="password"
            className="form-control"
            name="password"
            placeholder={t("password").toLowerCase()}
          />
        </div>
        {errors.password && (
          <div className="mb-3">
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
              {t(errors.password)}
            </span>
          </div>
        )}

        <div className="mb-3">
          <input
            value={formValues.__password}
            onChange={handleChange}
            type="password"
            className="form-control"
            name="__password"
            placeholder={t("re-enter password").toLowerCase()}
          />
        </div>
        {errors.__password && (
          <div className="mb-3">
            <span className="form-error">
              <i style={{ fontSize: ".8em" }} className="fa fa-info-circle"></i>{" "}
              {t(errors.__password)}
            </span>
          </div>
        )}

        <button
          onClick={__handleSubmit}
          type="submit"
          className="btn btn-primary my-3 mt-4 w-100"
        >
          {t("reset")}
        </button>
      </form>
    </div>
  );
};

export default RecoverPasswordForm;
