import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useEffect } from "react";
import { useRef, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getCarts } from "../../redux/slices/cartSlice";
import { getCommands } from "../../redux/slices/commandSlice";
import { handlePayment, updatePayment } from "../../redux/slices/paymentSlice";
import PaymentService from "../../services/PaymentService";
import {
  // convertTo5Multiple,
  formatPrice,
} from "../../utils/utils";
import CircularLoader from "../loader/CircularLoader";
// import PulseLoader from "../loader/PulseLoader";
import { PaymentMethod } from "../PaymentMethod";
import PriceDetail from "../PriceDetail";
import "./../styles/PaymentForm.css";
import { PAYMENT_STATUS } from "../../utils/constants";

const PaymentForm = ({
  onGoBack,
  amount,
  title,
  stripe_fee,
  dataForPayment,
  onPaymentSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const mobileFormRef = useRef();
  const cardFormRef = useRef();
  const operatorRef = useRef();
  const phoneRef = useRef();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [onPayment, setOnPayment] = useState(false);
  const [onWaitingMobilePayment, setOnWaitingMobilePayment] = useState(false);
  const [
    // pendingPaymentId,
    setPendingPaymentId,
  ] = useState(null);
  const [onStripePayment, setOnStripePayment] = useState(false);
  var paymentStatusCheckerId;
  var paymentStatusCheckerEllapsed = 0;

  const [total, setTotal] = useState({ mobile: 0, cc: 0 });

  const handleOpenForm = (e) => {
    if (e.target.value === "mobile") {
      mobileFormRef.current.classList.add("open");
      cardFormRef.current.classList.remove("open");
    } else {
      cardFormRef.current.classList.add("open");
      mobileFormRef.current.classList.remove("open");
    }
  };

  const startCheckingPaymentStatus = (paymentId) => {
    paymentStatusCheckerId = setInterval(() => {
      PaymentService.get(paymentId)
        .then((data) => {
          // user validated mobile payment
          if (paymentStatusCheckerEllapsed >= 1000 * 120) {
            clearInterval(paymentStatusCheckerId);
            setOnPayment(false);
            setOnWaitingMobilePayment(false);
            toast.warn("La transaction a été annulé.", { autoClose: false });
          }
          const status = data.data.data.payment.status;
          if (status === PAYMENT_STATUS.RECEIVED) {
            clearInterval(paymentStatusCheckerId);
            handlePaymentSuccess(data.data);
          } else if (status === PAYMENT_STATUS.REJECTED) {
            clearInterval(paymentStatusCheckerId);
            setOnPayment(false);
            setOnWaitingMobilePayment(false);
            toast.warn(
              "Le paiement a été rejété, veuillez réessayer après quelque minutes svp.",
              { autoClose: false }
            );
          }
          paymentStatusCheckerEllapsed += 5000;
        })
        .catch((err) => {
          console.log(err);
        });
    }, 5000);
  };

  const handleMobilePayment = (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phoneRef.current.value, "CM")) {
      return toast.error("Veuillez entrez un numéro de téléphone valide.");
    }

    const data = {
      payment_method: operatorRef.current.value,
      ...dataForPayment,
      phone: phoneRef.current.value,
    };

    setOnPayment(true);
    dispatch(handlePayment(data))
      .unwrap()
      .then((data) => {
        if (data.errors) {
          toast.error(data.errors.join("<br/>"));
          setOnPayment(false);
        } else {
          setOnPayment(false);
          setPendingPaymentId(data.data.payment.id);
          setOnWaitingMobilePayment(true);
          toast.info("Veuillez valider le paiement sur votre mobile svp.", {
            autoClose: true,
          });
          startCheckingPaymentStatus(data.data.payment.id);
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(
          "Une erreur s'est produite durant le paiement, veuillez vérifier vos informations, votre solde et réessayer svp."
        );
        setOnPayment(false);
      });
  };

  const handleStripePayment = async (e, stripe, elements) => {
    e.preventDefault();
    setOnStripePayment(true);
    const cardElement = elements.getElement(CardElement);
    const { error, token } = await stripe.createToken(cardElement);
    if (error) {
      toast.error("Error: " + error.message);
    } else {
      // ... SEND to your API server to process payment intent
      const data = {
        payment_method: "cc",
        stripe_data: token,
        ...dataForPayment,
        phone: phoneRef.current.value,
      };

      dispatch(handlePayment(data))
        .unwrap()
        .then((data) => {
          if (data.errors) {
            toast.error(data.errors.join("<br/>"));
            setOnStripePayment(false);
          } else {
            handlePaymentSuccess(data);
          }
        })
        .catch((err) => {
          toast.error(
            "Une erreur s'est produite durant le paiement, veuillez vérifier vos informations, votre solde et réessayer svp."
          );
          setOnStripePayment(false);
          console.log(err);
        });
    }
  };

  // rediriger l'utilisateur et afficher sa carte
  const handlePaymentSuccess = (data) => {
    dispatch(updatePayment(data)).then(() => {
      if (data.data.subscription) {
        localStorage.setItem("cardIdToPreview", data.data.subscription.id);
        navigate("/payment");
      } else if (data.data.command) {
        localStorage.setItem("commandIdToPreview", data.data.command.id);
        dispatch(getCarts());
        dispatch(getCommands());
        navigate("/payment");
      }
    });
  };

  const [paymentMethod, setPaymentMethod] = useState({
    value: "om",
    name: "Orange Money",
  });

  const handleSelectPaymentMethod = (method) => {
    setPaymentMethod(method);
    if (["om", "momo"].indexOf(method.value) >= 0) {
      operatorRef.current.value = method.value;
      handleOpenForm({ target: { value: "mobile" } });
    } else {
      handleOpenForm({ target: { value: "cc" } });
    }
  };
  const paymentFee = dataForPayment.payment_type === "command" ? 8 : 2.7;
  useEffect(() => {
    setTotal((value) => {
      return { ...value, mobile: (paymentFee * amount) / 100 + amount };
    });
    setTotal((value) => {
      return { ...value, cc: (stripe_fee * amount) / 100 + amount };
    });
  }, [amount, dataForPayment, paymentFee, stripe_fee]);

  return (
    <>
      {!onPayment && !onWaitingMobilePayment ? (
        <div className="paymentForm w-100 d-flex flex-column">
          <div className="px-4">
            <h4 className="text-dark fw-bold text-uppercase">
              méthode de paiement
            </h4>
            <PaymentMethod
              onSelect={handleSelectPaymentMethod}
              className="w-100"
            />
            <form
              onSubmit={handleMobilePayment}
              ref={mobileFormRef}
              className="mobileForm"
            >
              <div className="form-check p-0 m-0 py-1">
                <input
                  value={"mobile"}
                  onClick={handleOpenForm}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label className="form-check-label text-left">
                  Paiement par {paymentMethod.name} ({formatPrice(total.mobile)}
                  )
                </label>
              </div>
              <PriceDetail
                key={"PriceDetail-0"}
                price={parseInt(amount)}
                fee={paymentFee}
              />
              <div className="form-floating py-1 visually-hidden">
                <select
                  ref={operatorRef}
                  name="operator"
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                >
                  <option value="om">Orange</option>
                  <option value="momo">MTN</option>
                </select>
                <label htmlfor="floatingSelect">
                  Sélectionner l'opérateur mobile
                </label>
              </div>
              <div className="form-floating mb-3 pt-2">
                <input
                  required
                  ref={phoneRef}
                  name="phone"
                  type="tel"
                  className="form-control"
                  id="floatingInput"
                  placeholder="numéro de téléphone"
                />
                <label htmlfor="floatingInput">Numéro de téléphone</label>
              </div>
              <button type="submit" className="btn w-100 btn-primary my-2">
                Payer
              </button>
            </form>

            <form
              onSubmit={(event) => handleStripePayment(event, stripe, elements)}
              ref={cardFormRef}
              className="mobileForm"
            >
              <div className="form-check p-0 m-0 py-1">
                <input
                  value={"cc"}
                  onClick={handleOpenForm}
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label
                  className="form-check-label text-left"
                  htmlfor="flexRadioDefault1"
                >
                  Paiement par {paymentMethod.name} ({formatPrice(total.cc)})
                </label>
              </div>
              {total.mobile > 0 && (
                <PriceDetail
                  key={"PriceDetail-1"}
                  price={parseInt(amount)}
                  fee={stripe_fee}
                />
              )}
              <div className="CardElement my-4 py-4 form-control">
                <CardElement />
              </div>
              <button
                disabled={!stripe || onStripePayment}
                type="submit"
                className="btn w-100 btn-primary my-2"
              >
                {!onStripePayment ? "Payer" : <CircularLoader />}
              </button>
            </form>
          </div>
        </div>
      ) : (
        <CircularLoader
          content={
            !onWaitingMobilePayment ? (
              <small className="text-muted">paiement en cours</small>
            ) : (
              "Veuillez confirmer le paiement sur votre téléphone mobile SVP."
            )
          }
        />
      )}
    </>
  );
};

const StripeItemForm = (props) => <PaymentForm {...props} />;

export default StripeItemForm;
