/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import AdminContainer from "../components/AdminContainer";
import Box from "../components/Box";
import CardData from "../components/card/CardData";
import ImageViewer from "../components/ImageViewer";
import CircularLoader from "../components/loader/CircularLoader";
import { Modal } from "../components/Modal";
import useLanguage from '../hook/useLanguage';
import { getCards } from "../redux/slices/cardSlice";
import { getCommands } from "../redux/slices/commandSlice";
import { getItems } from "../redux/slices/itemSlice";
import {
  activeMember,
  adminMember,
  getMembers,
  superAdminMember,
} from "../redux/slices/memberSlice";
import { getPayments } from "../redux/slices/paymentSlice";
import { getSubscriptions } from "../redux/slices/subscriptionSlice";
import { getModelById, nFormatter, parseReadableDate } from "../utils/utils";
import "./../styles/PaymentPage.css";
import "./../styles/MemberPage.css";
import { PAYMENT_STATUS } from "../utils/constants";

const MemberPage = () => {
  const [language, t, handleChangeLanguage] = useLanguage()
  const memberData = useSelector((state) => state.member);
  const paymentData = useSelector((state) => state.payment);
  const subscriptionData = useSelector((state) => state.subscription);
  const commandData = useSelector((state) => state.command);
  const itemData = useSelector((state) => state.item);
  const cardData = useSelector((state) => state.card);
  const userData = useSelector((state) => state.user);

  const [imageViewer, setImageViewer] = useState(null);
  const [seasonViewer, setSeasonViewer] = useState(null);
  const [
    subscriptionToDownload,
    // setSubscriptionToDownload
  ] = useState(null);
  const [
    // preview,
    setPreview,
  ] = useState();
  // const [openCarousel, setOpenCarousel] = useState(false)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMembers({ reset: false, interval: null }));
    if (cardData === null) dispatch(getCards());
    if (paymentData === null) dispatch(getPayments());
    if (subscriptionData === null) dispatch(getSubscriptions());
    if (commandData === null) dispatch(getCommands());
    if (itemData === null) dispatch(getItems());
  }, [
    cardData,
    commandData,
    dispatch,
    itemData,
    paymentData,
    subscriptionData,
  ]);

  const memberDataColumns = [
    {
      name: "Profile",
      selector: (row) => {
        if (row.image) {
          return (
            <img
              onClick={() => setImageViewer(row.image)}
              src={row.image}
              className="w-50 p-2"
              style={{ borderRadius: "50%" }}
              alt=""
            />
          );
        }
        return (
          <small className="text-muted small" style={{ userSelect: "none" }}>
            non renseignée
          </small>
        );
      },
    },
    {
      name: "Date ins.",
      selector: (row) => {
        return parseReadableDate(row.created_at);
      },
      sortable: true,
      sortFunction: (a, b) => {
        let aDate = new Date(a.created_at);
        let bDate = new Date(b.created_at);
        return aDate - bDate;
      },
    },
    {
      name: "Nom(s)",
      selector: (row) => {
        return row.last_name;
      },
      sortable: true,
    },
    {
      name: "Prénom(s)",
      selector: (row) => {
        return row.first_name;
      },
      sortable: true,
    },
    {
      name: "Pays",
      selector: (row) => {
        return row.country;
      },
      sortable: true,
    },
    {
      name: "Numéro de téléphone",
      selector: (row) => {
        return row.phone;
      },
      sortable: true,
    },
    {
      name: "Adresse mail",
      selector: (row) => {
        return row.email;
      },
      sortable: true,
    },
    {
      name: "Ville",
      selector: (row) => {
        return row.city;
      },
    },
    {
      name: "Adresse",
      selector: (row) => {
        return row.address;
      },
      sortable: true,
    },
    {
      name: "Actif",
      selector: (row) => {
        return (
          <label className="switch" onClick={(e) => handleActiveMember(e, row)}>
            <input type="checkbox" checked={parseInt(row.active) === 1} />
            <div></div>
          </label>
        );
      },
      sortable: true,
      sortFunction: (a, b) => {
        return parseInt(a.active) - parseInt(b.active);
      },
    },
    {
      name: "Admin",
      selector: (row) => {
        return (
          <label className="switch" onClick={(e) => handleAdminMember(e, row)}>
            <input type="checkbox" checked={parseInt(row.admin) === 1} />
            <div></div>
          </label>
        );
      },
      sortable: true,
      sortFunction: (a, b) => {
        return parseInt(a.admin) - parseInt(b.admin);
      },
    },
    {
      name: "Super Admin",
      selector: (row) => {
        return (
          <label className="switch" onClick={(e) => handleSuperAdminMember(e, row)}>
            <input type="checkbox" checked={parseInt(row.super_admin) === 1} />
            <div></div>
          </label>
        );
      },
      sortable: true,
      sortFunction: (a, b) => {
        return parseInt(a.super_admin) - parseInt(b.super_admin);
      },
    },
  ];

  const handleActiveMember = (e, member) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirm({
      title: "Confirmation",
      message:
        parseInt(member.active) === 1
          ? `Voulez vous désactivez l'utilisateur ${member.last_name} ${member.first_name} ?`
          : `Voulez vous activez l'utilisateur ${member.last_name} ${member.first_name} ?`,
      callback: function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.target.setAttribute("disabled", "true");
        e.target.innerHTML =
          "<div className='spinner-grow spinner-grow-sm' role='status'></div>";
        // active user
        dispatch(
          activeMember({
            id: member.id,
            active: parseInt(member.active) === 1 ? 0 : 1,
          })
        ).then(() => {
          setConfirm(null);
        });
      },
    });
  };

  const handleSuperAdminMember = (e, member) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirm({
      title: "Confirmation",
      message:
        parseInt(member.admin) === 1
          ? `Voulez vous désactivez l'utilisateur ${member.last_name} ${member.first_name} en tant que super administrateur ?`
          : `Voulez vous activez l'utilisateur ${member.last_name} ${member.first_name} en tant que super administrateur ?`,
      callback: function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.target.setAttribute("disabled", "true");
        e.target.innerHTML =
          "<div className='spinner-grow spinner-grow-sm' role='status'></div>";
        // active user
        dispatch(
          superAdminMember({
            id: member.id,
            super_admin: parseInt(member.super_admin) === 1 ? 0 : 1,
          })
        ).then(() => {
          setConfirm(null);
        });
      },
    });
  };

  const handleAdminMember = (e, member) => {
    e.preventDefault();
    e.stopPropagation();
    setConfirm({
      title: "Confirmation",
      message:
        parseInt(member.admin) === 1
          ? `Voulez vous désactivez l'utilisateur ${member.last_name} ${member.first_name} en tant que administrateur ?`
          : `Voulez vous activez l'utilisateur ${member.last_name} ${member.first_name} en tant que administrateur ?`,
      callback: function (e) {
        e.preventDefault();
        e.stopPropagation();
        e.target.setAttribute("disabled", "true");
        e.target.innerHTML =
          "<div className='spinner-grow spinner-grow-sm' role='status'></div>";
        // active user
        dispatch(
          adminMember({
            id: member.id,
            admin: parseInt(member.admin) === 1 ? 0 : 1,
          })
        ).then(() => {
          setConfirm(null);
        });
      },
    });
  };

  const [confirm, setConfirm] = useState(null);
  const [q, setQ] = useState(null);

  const customTextFilter = (data, search) => {
    if (search === null) {
      return data;
    }
    search = search.toLowerCase();
    return data.filter((val, key) => {
      return (
        val.first_name.toLowerCase().includes(search) ||
        val.last_name.toLowerCase().includes(search)
      );
    });
  };

  const applyActionRestrictionsColumns = (data, user) => {
    const restrictedColumns = ["Actif", "Admin", "Super Admin"];
    const superAdmin = parseInt(user.super_admin) === 1;
    if (!superAdmin) {
      return data.filter((col) => {
        return restrictedColumns.indexOf(col.name) === -1;
      });
    }
    return data;
  };

  const getUniqueCountry = useMemo(
    () => (memberData) => {
      const countries = [];
      memberData.forEach((member) => {
        if (countries.indexOf(member.country) === -1)
          countries.push(member.country);
      });
      return countries;
    },
    []
  );

  const getEarnings = useMemo(
    () => (paymentData, cardData, subscriptionData, commandData) => {
      let total = 0;
      paymentData.forEach((payment) => {
        // remember later to also add shop earnings
        if (payment.subscription_id && payment.status === PAYMENT_STATUS.RECEIVED) {
          const subscription = getModelById(
            subscriptionData,
            payment.subscription_id
          );
          if (subscription) {
            const card = getModelById(cardData, subscription.card_id);
            total += parseFloat(card?.card_price);
          }
        } else if (payment.command_id && payment.status === PAYMENT_STATUS.RECEIVED) {
          const command = getModelById(commandData, payment.command_id);
          if (command) {
            total += parseFloat(command.price);
          }
        }
        // we will probably also add donation earnings
      });
      return total;
    },
    []
  );

  const earnings =
    paymentData && commandData && cardData && itemData && subscriptionData
      ? nFormatter(
          getEarnings(paymentData, cardData, subscriptionData, commandData),
          2
        )
      : "...";

  
  
    return (
    <AdminContainer>
      {memberData !== null && userData !== null ? (
        <div className="">
          <h4 className="fw-bold text-light text-uppercase">membres</h4>
          <div className="CardDataContainer my-3 d-flex gap-3 justify-content-between p-0 m-0">
            <CardData
              value={`${memberData.length}+`}
              label={"membres"}
              icon={<i className="fa-solid fa-user fa-5x text-light"></i>}
            />
            <CardData
              value={`${getUniqueCountry(memberData).length}+`}
              label={"pays repartis"}
              icon={
                <i className="fa-solid fa-earth-africa fa-5x text-light"></i>
              }
            />
            <CardData
              value={`${earnings}`}
              label={
                <span>
                  gagnez chez <br />
                  les membres
                </span>
              }
              icon={
                <i className="fa-solid fa-sack-dollar fa-5x text-light"></i>
              }
            />
          </div>
          <div className="d-flex justify-content-between w-100 mb-2 align-items-center">
            <h5 className="fw-bold text-light text-uppercase">
              listes des membres
            </h5>
            {/* <span className="">{q && q.length > 0 && "Résultat pour :   '" + q + "'"}</span> */}
            <input
              maxLength={10}
              placeholder="Recherchez un membre"
              onChange={(e) => setQ(e.target.value)}
              className="w-50 searchBar bg-transparent form-control mr-sm-2"
              type="search"
              aria-label="Search"
            ></input>
          </div>
          <DataTable
            className="PaymentHistory"
            columns={applyActionRestrictionsColumns(
              memberDataColumns,
              userData
            )}
            data={customTextFilter(memberData, q)}
            striped
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
          />
        </div>
      ) : (
        <Box content={<CircularLoader />} />
      )}

      {imageViewer && (
        <ImageViewer
          image={imageViewer}
          season={seasonViewer}
          onClose={() => {
            setImageViewer(null);
            setSeasonViewer(null);
            setPreview(null);
          }}
          open={subscriptionToDownload !== null}
        />
      )}

      {confirm && (
        <Modal
          onClose={(e) => {
            setConfirm(null);
          }}
          open={confirm ?? false}
          title={
            <div className="text-uppercase" style={{ color: "#e89f29" }}>
              <i className="fas fa-exclamation-circle fa-1x"></i>{" "}
              {confirm.title}
            </div>
          }
        >
          {!confirm.onConfirm && (
            <div className="d-flex flex-column justify-content-between p-3">
              <div>{confirm.message}</div>
              <div className="d-flex justify-content-end w-100 mt-3">
                <button
                  style={{ background: "#e89f29 !important" }}
                  className="downloadBtn warning text-uppercase"
                  onClick={(e) => confirm.callback(e)}
                >
                  Confirmer
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </AdminContainer>
  );
};

export default MemberPage;
