/* eslint-disable no-unused-vars */
import { NavLink, useNavigate } from "react-router-dom";
import AppContainer from "../components/AppContainer";
import useLanguage from "../hook/useLanguage";
import "./../styles/HomePage.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCards } from "../redux/slices/cardSlice";
import { getItems } from "../redux/slices/itemSlice";
import { useState } from "react";
import { CardCarousel } from "../components/card/CardCarousel";
import { toast } from "react-toastify";
import CircularLoader from "../components/loader/CircularLoader";
import ShopItem from "../components/shop/ShopItem";
import { shuffleArray } from "../utils/utils";

const HomePage = () => {
  const [language, t, handleChangeLanguage] = useLanguage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardData = useSelector((state) => state.card);
  const itemData = useSelector((state) => state.item);
  const userData = useSelector((state) => state.user);
  const [cardItems, setCardItems] = useState([]);
  const [activeCard, setActiveCard] = useState(null);
  const [activeCardIndex, setActiveCardIndex] = useState(2);
  const userToken = localStorage.getItem("authToken");

  useEffect(() => {
    if (cardData === null) {
      dispatch(getCards())
        .unwrap()
        .then((data) => {
          setCardItems(data);
        });
    }
  }, [cardData, dispatch]);

  useEffect(() => {
    if (itemData === null) {
      dispatch(getItems());
    }
  }, [dispatch, itemData]);

  useEffect(() => {
    setCardItems(cardData);
  }, [cardData]);

  useEffect(() => {
    cardItems && setActiveCard(cardItems[2]);
  }, [cardItems, setActiveCard]);

  const handlePrev = () => {
    setCardItems((prevCards) => {
      const lastCard = prevCards[prevCards?.length - 1];
      const newCards = [lastCard, ...prevCards.slice(0, prevCards?.length - 1)];
      return newCards;
    });
  };

  const handleNext = () => {
    setCardItems((prevCards) => {
      const firstCard = prevCards[0];
      const newCards = [...prevCards.slice(1), firstCard];
      return newCards;
    });
  };

  const handleSelect = (selectedCard) => {
    if (userData === null) {
      return toast.warning(
        <div>
          Vous devez{" "}
          <span onClick={() => navigate("/register")} className="deeplink">
            créer un compte
          </span>{" "}
          ou vous{" "}
          <span onClick={() => navigate("/login")} className="deeplink">
            connectez
          </span>{" "}
          pour continuer l'achat de votre RACING carte.
        </div>,
        { autoClose: false }
      );
    }
    navigate("/card");
  };

  const handleAddToCart = (e) => {
    if (userData === null) {
      return toast.warning(
        <div>
          Vous devez{" "}
          <span onClick={() => navigate("/register")} className="deeplink">
            créer un compte
          </span>{" "}
          ou vous{" "}
          <span onClick={() => navigate("/login")} className="deeplink">
            connectez
          </span>{" "}
          pour continuer votre achat.
        </div>,
        { autoClose: true }
      );
    }
    navigate("/shop");
  };

  return (
    <AppContainer>
      <video className="mainVideo" autoPlay loop muted playsInline>
        <source src="./../videos/bg-racing.mp4"></source>
        Your browser does not support video
      </video>

      <div className="floattingController">
        <div>
          <div id="homeController" className="floattingController__button">
            Accueil
          </div>
          <div id="cardController" className="floattingController__button">
            Carte Membre
          </div>
          <div id="shopController" className="floattingController__button">
            Boutique
          </div>
        </div>
      </div>

      <div id="homePage" className="homeContent p-0 m-0">
        <div
          id="homeSection"
          className="homeContent__item homeContent__home d-flex align-items-center"
        >
          <div className="container-fluid">
            <div className="d-flex ms-lg-5 flex-column justify-content-center h-100 col-12 col-md-8 col-lg-4">
              <img
                className="brand ms-2"
                src="./racing-club.png"
                alt="RACING Club"
              />
              <h3 className="text-light mt-4">Le Racing Club</h3>
              <p className="text-light">
                Le Racing Club de Bafoussam est un club de football camerounais
                fondé en milieu des années 1950 à Bafoussam. À la suite d'un
                exploit dans le championnat de football national dans la
                décennie 1960, l'équipe reçoit le surnom de "Tout-Puissant de
                l'Ouest" TPO. Racing connaît son apogée dans la décennie de
                1990, en remportant 4 titres de champion de Cameroun et une fois
                la coupe du Cameroun de football. Racing évolue à domicile au
                stade municipale de Bamendzi.
              </p>
              {userToken ? (
                <NavLink to={"/profil"}>
                  <button className="btn btn-primary text-white col-9 col-md-8 col-lg-6">
                    Consulter mon profile
                  </button>
                </NavLink>
              ) : (
                <NavLink to={"/register"}>
                  <button className="btn btn-primary text-white col-9 col-md-8 col-lg-6">
                    Créer un compte
                  </button>
                </NavLink>
              )}
            </div>
            {/* <div className="bonBaDjob d-flex">
                <div className="bonBaDjob__content d-flex justify-content-between gap-3 align-items-center">
                  <div className="d-flex gap-3">
                    {Array(7)
                      .fill(0)
                      .map((e, x) => (
                        <div key={x} className="verticalLine"></div>
                      ))}
                  </div>
                  BON BA DJOB
                </div>
              </div> */}
          </div>
        </div>

        {/* caroussel here is in test.txt*/}
        <div
          id="cardSection"
          className="homeContent__item homeContent__card d-flex justify-content-center align-items-center align-items-lg-end"
        >
          {cardItems && cardItems.length > 0 ? (
            <CardCarousel
              onSelect={handleSelect}
              onPrev={handlePrev}
              onNext={handleNext}
              cardItems={cardItems}
              activeCardIndex={activeCardIndex}
            />
          ) : (
            <CircularLoader />
          )}
        </div>

        <div
          id="itemSection"
          className="homeContent__item d-flex flex-column align-items-center justify-content-center"
        >
          {itemData ? (
            <>
              <h3 className="text-light text-uppercase text-center">
                Explorer nos différents articles
              </h3>
              <div className="d-flex" style={{ overflow: "auto" }}>
                {shuffleArray(itemData.slice())
                  .slice(0, 3)
                  .map((item, key) => {
                    return (
                      <div
                        key={item.id}
                        className={
                          "ShopItemContainer " + (key > 2 ? "mt-3" : "")
                        }
                      >
                        <ShopItem
                          key={item.id}
                          editable={false}
                          actionnabe={false}
                          onEdit={null}
                          onAddToCart={handleAddToCart}
                          item={item}
                        />
                      </div>
                    );
                  })}
              </div>

              <button
                onClick={handleAddToCart}
                className="btn btn-primary text-uppercase fw-bold"
              >
                voir plus d'articles <i className="fa fa-right-arrow"></i>
              </button>
            </>
          ) : (
            <CircularLoader />
          )}
        </div>
      </div>
    </AppContainer>
  );
};

export default HomePage;
