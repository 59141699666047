import { parseReadableDate } from "../../../utils/utils";

const GameHistoryHeader = ({ lastGame, nextGame }) => {
  return (
    <div className="mt-3 d-flex flex-column justify-content-center align-items-center w-100 p-0 m-0">
      {nextGame && (
        <div className="d-flex flex-column justify-content-between align-items-center">
          <div className="text-white fs-2 p-3 pb-0">
            {parseReadableDate(nextGame.start_at, false).split("à")[0]}
          </div>
          <div className="text-white pt-0">à</div>
          <div className="text-white p-3 pt-0">
            {parseReadableDate(nextGame.start_at, false).split("à")[1]}
          </div>
          <div className="d-flex justify-content-between gap-5 align-items-center p-3">
            <img className="imageVS" src={nextGame.team1.logo} alt="" />
            <div className="text-white fs-1 fw-bold">VS</div>
            <img className="imageVS" src={nextGame.team2.logo} alt="" />
          </div>
        </div>
      )}
      <div className="w-100 mt-3 d-flex justify-content-between flex-column flex-md-row align-items-center">
        <h6 className="fw-bold text-light">
          Stade: {lastGame ? lastGame.stadium : ""}
        </h6>
        {lastGame && (
          <div className="d-flex flex-column flex-md-row justify-content-between mt-4 mt-md-0 align-items-center gap-2">
            <h6 className="fw-bold text-light align-self-start align-self-md-center">
              Score dernier match
            </h6>
            <div className="scoreResultContainer gap-5 p-4 d-flex rounded justify-content-between align-items-center">
              <div className="fw-bold">{lastGame.team1.name}</div>
              <div className="scoreResultContainer__score border rounded p-2 px-3">
                {lastGame.score}
              </div>
              <div className="fw-bold">{lastGame.team2.name}</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default GameHistoryHeader;
