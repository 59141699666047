

const CircleGroup = () => {
    return <div className='circle-container d-flex flex-column gap-2'>
        {
            Array(7).fill(0).map((_, key1) => {
                return <div key={key1} className='d-flex justify-content-between gap-2'>
                    {
                        Array(4).fill(0).map((_, key2) => {
                            return <div id={`circle-${key1}${key2}`} key={`${key1}${key2}`} className='circle-container__item'></div>
                        })
                    }
                </div>
            })
        }
    </div>;
}

export default CircleGroup;