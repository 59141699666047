import axios from "axios"
axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("authToken")}`;

const getAll = ()=>{
    return axios.get(`seasons`, {
        headers:{
            "Authorization": `Bearer ${localStorage.getItem("authToken")}`
        }
    }).then((data)=>{
        return data;
    })
}


const SeasonService = {
    getAll
}

export default SeasonService;