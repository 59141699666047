/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import "./../../styles/LoginForm.css";
import "./../../styles/ProfilForm.css";
import "react-phone-number-input/style.css";
import { useEffect, useRef, useState } from "react";
import useLanguage from "../../../hook/useLanguage";
import useForm from "../../../hook/useForm";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { formatPrice } from "../../../utils/utils";
import { updateGame } from "../../../redux/slices/gameSlice";

const EditGameForm = ({ game, onBack }) => {
  const navigate = useNavigate();
  const [language, t, handleChangeLanguage] = useLanguage();

  const [
    formValues,
    errors, setErrors,
    handleChange,
    handleSubmit,
  ] = useForm(
    { ...game, team1Name: game.team1.name, team2Name: game.team2.name },
    (name, value) => {
      if (name === "stadium") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team1Name") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team2Name") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team1Logo") {
        if (!image) return "you must provide item image";
      }
      if (name === "team2Logo") {
        if (!image) return "you must provide item image";
      }
      if (name === "startAt") {
        if (Number(value) < 1) return "quantity must be greather than 1";
      }
      if (name === "endAt") {
        if (Number(value) < 500)
          return `price must be greather than ${formatPrice(500)}`;
      }
      return null;
    }
  );

  const [image, setImage] = useState(null);
  const avatarRef = useRef();
  const formEditRef = useRef();
  const submitBtnRef = useRef();
  const inputImageRef = useRef([null, null]);

  const [previewImageSrc, setPreviewImageSrc] = useState([null, null]);

  useEffect(() => {
    setPreviewImageSrc([formValues.team1.logo, formValues.team2.logo]);
  }, [formValues.team1.logo, formValues.team2.logo]);

  const dispatch = useDispatch();

  const __handleSubmit = (e, match) => {
    e.preventDefault();

    const isSubmittable = handleSubmit(e);
    if (!isSubmittable) return;

    submitBtnRef.current.setAttribute("disabled", "true");
    submitBtnRef.current.innerHTML =
      "modification...<div className='spinner-border spinner-border-sm text-secondary' role='status'></div>";

    const formData = new FormData(formEditRef.current);

    let updateData = {
      id: game.id,
      stadium: formData.get("stadium"),
      team1Name: formData.get("team1Name"),
      team1Logo: formData.get("team1.logo"),
      team2Name: formData.get("team2Name"),
      team2Logo: formData.get("team2.logo"),
      startAt: formData.get("start_at"),
      endAt: formData.get("end_at"),
      score: formData.get("score"),
    };

    dispatch(
      updateGame({
        ...updateData,
      })
    )
      .unwrap()
      .then((data) => {
        submitBtnRef.current.removeAttribute("disabled");
        submitBtnRef.current.innerHTML = "MODIFIER";
        formEditRef.current.reset();
        toast.success("Le match a été modifié.");
        onBack();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Veuillez remplir correctement le formulaire");
      })
      .finally(() => {
        submitBtnRef.current.removeAttribute("disabled");
        submitBtnRef.current.innerHTML = "MODIFIER";
      });
  };

  const handleInputImageChange = (e, i) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      let previewImageSrcData = previewImageSrc.slice();
      previewImageSrcData[i] = reader.result;
      setPreviewImageSrc(previewImageSrcData);
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <h5 className="d-flex fw-bold text-light gap-2">
        <i
          onClick={onBack}
          className="backButton fa fa-arrow-left-long fa-1x"
        ></i>{" "}
        Modifier l'article {game.name}
      </h5>
      <div className="LoginForm col-12 col-md-9">
        <form ref={formEditRef} className="p-2 m-2 py-4">
          <div className="form-floating mb-3 pt-2">
            <input
              value={formValues.score}
              onChange={handleChange}
              required
              name="score"
              type="text"
              className="form-control"
              id="floatingInput"
            />
            <label htmlfor="floatingInput">Score</label>
          </div>
          <div className="form-floating mb-3 pt-2">
            <input
              value={formValues ? formValues.stadium : ""}
              onChange={handleChange}
              required
              name="stadium"
              type="text"
              className="form-control"
              id="floatingInput"
            />
            <label htmlfor="floatingInput">Nom du stade</label>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
            <div className="form-floating w-100 mb-3 pt-2">
              <input
                value={formValues.team1Name}
                onChange={handleChange}
                required
                name="team1Name"
                type="text"
                className="form-control"
                id="floatingInput"
              />
              <label htmlfor="floatingInput">Nom de l'équipe 1</label>
            </div>
            <div className="form-floating mb-3 pt-2 w-100">
              <input
                value={formValues.team2Name}
                onChange={handleChange}
                required
                name="team2Name"
                type="text"
                className="form-control"
                id="floatingInput"
              />
              <label htmlfor="floatingInput">Nom de l'équipe 2</label>
            </div>
          </div>
          <div className="row">
            <div className="form-floating mb-3 pt-2 col-12 col-md-6">
              <input
                value={formValues.start_at}
                onChange={handleChange}
                required
                name="start_at"
                type="datetime-local"
                className="form-control"
                id="floatingInput"
              />
              <label htmlfor="floatingInput">Date de début</label>
            </div>
            <div className="form-floating mb-3 pt-2 col-12 col-md-6">
              <input
                value={formValues.end_at}
                onChange={handleChange}
                required
                name="end_at"
                type="datetime-local"
                className="form-control"
                id="floatingInput"
              />
              <label htmlfor="floatingInput">Date de fin</label>
            </div>
          </div>

          <div className="d-flex flex-column flex-md-row justify-content-between gap-2">
            <div className="form-floating py-1 w-100">
              <input
                name="team1.logo"
                accept="image/*"
                onChange={(e) => handleInputImageChange(e, 0)}
                ref={(el) => (inputImageRef.current[0] = el)}
                type="file"
                hidden
              />

              <div
                className="d-flex justify-content-center align-items-center imageContainer"
                style={{ height: "100px" }}
              >
                <div
                  onClick={() => inputImageRef.current[0].click()}
                  title="Ajouter une image"
                  className="w-50 d-flex flex-column justify-content-center align-items-center h-100"
                >
                  {previewImageSrc[0] === null ? (
                    <>
                      <i className="fa-solid fa-image fa-2x"></i>
                      <span>Logo Equipe 1</span>
                    </>
                  ) : (
                    <>
                      <img
                        className="w-75"
                        src={previewImageSrc[0]}
                        alt="preview"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="form-floating w-100 py-1">
              <input
                name="team2.logo"
                accept="image/*"
                onChange={(e) => handleInputImageChange(e, 1)}
                ref={(el) => (inputImageRef.current[1] = el)}
                type="file"
                hidden
              />

              <div className="d-flex justify-content-center align-items-center imageContainer">
                <div
                  onClick={() => inputImageRef.current[1].click()}
                  title="Ajouter une image"
                  className="w-50 d-flex flex-column justify-content-center align-items-center h-100"
                >
                  {previewImageSrc[1] === null ? (
                    <>
                      <i className="fa-solid fa-image fa-2x"></i>
                      <span>Logo Equipe 2</span>
                    </>
                  ) : (
                    <>
                      <img
                        className="w-75"
                        src={previewImageSrc[1]}
                        alt="preview"
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <button
            ref={submitBtnRef}
            onClick={__handleSubmit}
            type="submit"
            className="btn btn-primary my-3 mt-4 mx-auto col-12"
          >
            {t("Modifier")}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditGameForm;
