import { useState } from "react";
import { toast } from "react-toastify";
import { formatPrice } from "../../utils/utils";
import { SpinnerLoader } from "../loader/SpinnerLoader";
import "./../../styles/CartItem.css";

const CartItem = ({ onRemove, cart }) => {
  const [loading, setLoading] = useState(false);

  const _onRemove = async () => {
    setLoading(true);
    await onRemove({ ...cart })
      .unwrap()
      .then((response) => {
        toast.success(`${cart.item.name} a été supprimé au panier.`);
      })
      .catch((err) => {
        toast.warn(`${cart.item.name} n'a pas pu etre supprimé du panier.`);
        // console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="CartItem p-2 w-100">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="CartItem__image" data-count={cart.quantity}>
          <img src={cart.item.image} className="" alt="" />
        </div>
        <div className="d-flex flex-column justify-content-between">
          <h6>{cart.item.name}</h6>
          <span className="CartItem__price text-muted">
            {formatPrice(cart.price)}
          </span>
        </div>
        <div>
          {loading ? (
            <SpinnerLoader />
          ) : (
            <i onClick={() => _onRemove(cart)} className="fa fa-times"></i>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartItem;
