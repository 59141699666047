/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import "./../../styles/LoginForm.css";
import "./../../styles/ProfilForm.css";
import "react-phone-number-input/style.css";
import { useEffect, useRef, useState } from "react";
import useLanguage from "../../../hook/useLanguage";
import useForm from "../../../hook/useForm";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { formatPrice } from "../../../utils/utils";
import { updateVote } from "../../../redux/slices/voteSlice";

const EditVoteForm = ({ vote, games, players, onBack }) => {
  const navigate = useNavigate();
  const [language, t, handleChangeLanguage] = useLanguage();

  const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
    { ...vote, startAt: vote.interval[0], endAt: vote.interval[1] },
    (name, value) => {
      if (name === "stadium") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team1Name") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team2Name") {
        if (value.length < 3) return "incorrect stadium name";
      }
      if (name === "team1Logo") {
        if (!image) return "you must provide item image";
      }
      if (name === "team2Logo") {
        if (!image) return "you must provide item image";
      }
      if (name === "startAt") {
        if (Number(value) < 1) return "quantity must be greather than 1";
      }
      if (name === "endAt") {
        if (Number(value) < 500)
          return `price must be greather than ${formatPrice(500)}`;
      }
      return null;
    }
  );
  const [newVotePlayersId, setNewVotePlayersId] = useState(vote.players_id);

  const [image, setImage] = useState(null);
  const formEditRef = useRef();
  const submitBtnRef = useRef();
  const submitEditBtnRef = useRef();

  const dispatch = useDispatch();

  const __handleSubmit = (e) => {
    e.preventDefault();

    const isSubmittable = handleSubmit(e);
    if (!isSubmittable)
      return toast.error("Veuillez remplir correctement le formulaire");

    submitEditBtnRef.current.setAttribute("disabled", "true");
    submitEditBtnRef.current.innerHTML =
      "modification...<div className='spinner-border spinner-border-sm text-secondary' role='status'></div>";

    const formData = new FormData(formEditRef.current);
    const gameId = parseInt(formData.get("game_id"));
    let updateData = {
      id: vote.id,
      name: formData.get("name"),
      interval: [formData.get("startAt"), formData.get("endAt")],
      players_id: newVotePlayersId,
    };
    if (gameId !== 0) {
      updateData = { ...updateData, game_id: gameId };
    }

    dispatch(
      updateVote({
        ...updateData,
      })
    )
      .unwrap()
      .then((data) => {
        submitEditBtnRef.current.removeAttribute("disabled");
        submitEditBtnRef.current.innerHTML = "modifier";
        formEditRef.current.reset();
        toast.success("Le vote a été modifié.");
        onBack();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Veuillez remplir correctement le formulaire");
      })
      .finally(() => {
        submitEditBtnRef.current.removeAttribute("disabled");
        submitEditBtnRef.current.innerHTML = "MODIFIER";
      });
  };

  const addNewVotePlayerId = (id) => {
    const ids = newVotePlayersId.slice();
    ids.push(id);
    setNewVotePlayersId(ids);
  };

  const removeNewVotePlayerId = (id) => {
    const ids = newVotePlayersId.slice().filter((value) => {
      return value !== id;
    });
    setNewVotePlayersId(ids);
  };

  const toogleNewVotePlayerId = (id) => {
    if (newVotePlayersId.includes(id)) {
      removeNewVotePlayerId(id);
    } else {
      addNewVotePlayerId(id);
    }
  };

  const selectAllPlayerForNewVote = () => {
    setNewVotePlayersId(players.map((player) => player.id));
  };

  const deselectAllPlayerForNewVote = () => {
    setNewVotePlayersId([]);
  };

  return (
    <>
      <h5 className="d-flex fw-bold text-light gap-2">
        <i
          onClick={onBack}
          className="backButton fa fa-arrow-left-long fa-1x"
        ></i>{" "}
        Modifier le vote {vote.name}
      </h5>
      <div className="LoginForm col-12 col-md-9">
        <form ref={formEditRef} className="p-2 m-2 py-4">
          <div className="form-floating mb-3 pt-2">
            <input
              onChange={handleChange}
              value={formValues.name}
              required
              name="name"
              type="text"
              className="form-control"
              id="floatingInput"
            />
            <label htmlfor="floatingInput">Label du vote</label>
          </div>
          <div className="form-floating mb-3 pt-2">
            <select
              onChange={handleChange}
              value={formValues.game_id}
              required
              name="game_id"
              type="text"
              className="form-control"
              id="floatingInput"
            >
              <option value={0}>Meilleur Joueur</option>
              {games.map((game, key) => {
                return (
                  <option key={key} value={game.id}>
                    {game.team1.name} VS {game.team2.name} ({game.id})
                  </option>
                );
              })}
            </select>
            <label htmlfor="floatingInput">Type de vote</label>
          </div>
          <div className="row">
            <div className="form-floating mb-3 pt-2 col-12 col-md-6">
              <input
                onChange={handleChange}
                value={formValues.startAt}
                onLoad={(e) => {
                  e.target.valueAsDate = new Date();
                }}
                required
                name="startAt"
                type="datetime-local"
                className="form-control"
                id="floatingInput"
                placeholder="titre du message"
              />
              <label htmlfor="floatingInput">Date de début</label>
            </div>
            <div className="form-floating mb-3 pt-2 col-12 col-md-6">
              <input
                onChange={handleChange}
                value={formValues.endAt}
                onLoad={(e) => {
                  e.target.valueAsDate = new Date();
                }}
                required
                name="endAt"
                type="datetime-local"
                className="form-control"
                id="floatingInput"
                placeholder="titre du message"
              />
              <label htmlfor="floatingInput">Date de fin</label>
            </div>
          </div>

          <div className="">
            <div className="d-flex justify-content-end gap-1">
              <div
                onClick={selectAllPlayerForNewVote}
                className="selectPlayerContainerActionsBtn"
              >
                Select All
              </div>

              <div
                onClick={deselectAllPlayerForNewVote}
                className="selectPlayerContainerActionsBtn"
              >
                Deselect All
              </div>
            </div>
            <div
              title="Ajouter des joueurs au vote"
              className="selectPlayerContainer flex-wrap d-flex border justify-content-start p-2"
            >
              {players &&
                players.map((player) => {
                  return (
                    <div
                      onClick={() => toogleNewVotePlayerId(player.id)}
                      className={
                        "chip " +
                        (newVotePlayersId.includes(player.id) ? "active" : "")
                      }
                    >
                      <div>
                        {player.last_name} {player.first_name}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <button
            ref={submitEditBtnRef}
            onClick={__handleSubmit}
            type="submit"
            className="btn btn-primary my-3 mt-4 mx-auto col-12"
          >
            {t("Modifier")}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditVoteForm;
