import { useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import {
  adaptTeamName,
  filterGame,
  parseReadableDate,
} from "../../utils/utils";
import TabPane from "../navigation/TabPane";
import "./../styles/GameHistory.css";

const GameHistory = ({ data }) => {
  const gameDataColumns = useMemo(
    () => [
      {
        name: "Equipe 1",
        selector: (row) => {
          return (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                style={{ width: "40px", height: "40px", borderRadius: "25px" }}
                src={row.team1.logo}
                alt={row.team1.name}
              />
              <span className="d-flex flex-column justify-content-center align-items-center">
                {adaptTeamName(row.team1.name)}
              </span>
            </div>
          );
        },
      },
      {
        name: "Equipe 2",
        selector: (row) => {
          return (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                style={{ width: "40px", height: "40px", borderRadius: "25px" }}
                src={row.team2.logo}
                alt={row.team2.name}
              />
              <span>{adaptTeamName(row.team2.name)}</span>
            </div>
          );
        },
      },
      {
        name: "Stade",
        selector: (row) => {
          return row ? row.stadium : "";
        },
      },
      {
        name: "Date",
        selector: (row) => {
          return parseReadableDate(new Date(row.start_at));
        },
        sortable: true,
        sortFunction: (a, b) => new Date(a.start_at) > new Date(b.start_at),
      },
      {
        name: "Score",
        selector: (row) => {
          return (
            <div className="voteResult border w-100 fs-6 px-3">{row.score}</div>
          );
        },
        sortable: false,
      },
    ],
    []
  );
  const [key, setKey] = useState(0);

  return (
    <div className="GameHistory mt-3 p-3">
      <div className="d-flex gap-2 align-items-center mb-3">
        <img src="./racing-club.png" className="img-fluid brand" alt="" />
        <h3 className="text-light">MTN Elite Two</h3>
      </div>
      <TabPane
        onSelect={(val) => setKey(val)}
        activeKey={key}
        label1={
          <>
            <i className="fa fa-history" aria-hidden="true"></i> historique des
            matchs
          </>
        }
        label2={
          <>
            <i className="bi bi-calendar-week"></i> matchs à venir
          </>
        }
        child1={
          <div className="">
            <h4 className="fw-bold text-light text-uppercase">
              historique des matchs
            </h4>
            <DataTable
              className="PaymentHistory"
              columns={gameDataColumns}
              data={filterGame(data, true)}
              striped
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            />
          </div>
        }
        child2={
          <div className="">
            <h4 className="fw-bold text-light text-uppercase">
              matchs à venir
            </h4>
            <DataTable
              className="PaymentHistory"
              columns={gameDataColumns}
              data={filterGame(data, false)}
              striped
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 15, 20, 25, 30]}
            />
          </div>
        }
      />
    </div>
  );
};

export default GameHistory;
