/* eslint-disable no-unused-vars */
import { useNavigate } from "react-router-dom";
import "./../styles/LoginForm.css";
import "./../styles/ProfilForm.css";
import "react-phone-number-input/style.css";
import { useRef, useState } from "react";
import useLanguage from "../../hook/useLanguage";
import useForm from "../../hook/useForm";
import "react-image-crop/dist/ReactCrop.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { formatPrice } from "../../utils/utils";
import {
  // addItem,
  editItem,
} from "../../redux/slices/itemSlice";
// import userEvent from '@testing-library/user-event';

const EditItemForm = ({ item, onBack }) => {
  const navigate = useNavigate();
  const [language, t, handleChangeLanguage] = useLanguage();

  const [formValues, errors, setErrors, handleChange, handleSubmit] = useForm(
    { name: "", image: null, quantity: "", price: "", ...item },
    (name, value) => {
      if (name === "name") {
        if (value.length < 3) return "incorrect product name";
      }
      if (name === "image") {
        if (!image && !item.image) return "you must provide item image";
      }
      if (name === "quantity") {
        if (Number(value) < 1) return "quantity must be greather than 1";
      }
      if (name === "price") {
        if (Number(value) < 500)
          return `price must be greather than ${formatPrice(500)}`;
      }
      return null;
    }
  );

  const validateForm = () => {
    let errors = {
      name: "",
      image: "",
      quantity: "",
      price: "",
    };

    let isValid = true;

    if (!formValues.name) {
      errors.name = "Name est requis";
      isValid = false;
    }

    if (!formValues.quantity) {
      errors.quantity = "Quantity est requis";
      isValid = false;
    }

    if (!formValues.price) {
      errors.price = "Price est requis";
      isValid = false;
    }

    setErrors(errors);

    return isValid;
  };

  const [image, setImage] = useState(null);
  const avatarRef = useRef();
  const formRef = useRef();

  const dispatch = useDispatch();

  const __handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (validateForm()) {
      e.target.setAttribute("disabled", "true");
      e.target.innerHTML =
        '<div className="spinner-grow spinner-grow-sm" role="status"><span className="visually-hidden">Loading...</span></div>';

      const data = new FormData(formRef.current);
      data.append("id", item.id);
      if (!image) {
        data.delete("image");
      } else {
        // data.set('image', image)
      }

      dispatch(editItem(data))
        .then((response) => {
          toast.success(`${data.get("name")} a été modifié.`);
          onBack();
        })
        .catch((err) => {
          console.log(err.response.data);
          const errors = err.response.data.errors;
          setErrors(errors);
          console.log(err);
        })
        .finally(() => {
          e.target.removeAttribute("disabled");
          e.target.innerHTML = "Modifier";
        });
    } else {
      // console.log(formValues);
    }
  };

  const handleChangeImage = (e) => {
    avatarRef.current.click();
  };

  const handleImageChange = (e) => {
    const fileReader = new FileReader();
    fileReader.onloadend = (event) => {
      setImage(fileReader.result);
    };

    fileReader.readAsDataURL(e.target.files[0]);
  };

  return (
    <>
      <h5 className="d-flex fw-bold text-light gap-2">
        <i
          onClick={onBack}
          className="backButton fa fa-arrow-left-long fa-1x"
        ></i>{" "}
        Modifier l'article {item.name}
      </h5>
      <div className="LoginForm col-12 col-md-9">
        <form ref={formRef} className="p-2 m-2 py-4">
          <div className="row align-items-center">
            <div className="col-12 col-md-6">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <input
                      value={formValues.name}
                      onChange={handleChange}
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder={t("Nom du produit")}
                    />
                    {errors.name && (
                      <span className="form-error">
                        <i
                          style={{ fontSize: ".8em" }}
                          className="fa fa-info-circle"
                        ></i>{" "}
                        {t(errors.name)}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="mb-3">
                    <input
                      value={formValues.quantity}
                      onChange={handleChange}
                      type="number"
                      min={0}
                      step={1}
                      className="form-control"
                      name="quantity"
                      placeholder={t("Quantité")}
                    />
                    {errors.quantity && (
                      <span className="form-error">
                        <i
                          style={{ fontSize: ".8em" }}
                          className="fa fa-info-circle"
                        ></i>{" "}
                        {t(errors.quantity)}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <input
                    value={formValues.price}
                    onChange={handleChange}
                    type="number"
                    min={500}
                    step={5}
                    className="form-control"
                    name="price"
                    placeholder={t("Prix du produit")}
                  />
                  {errors.price && (
                    <span className="form-error">
                      <i
                        style={{ fontSize: ".8em" }}
                        className="fa fa-info-circle"
                      ></i>{" "}
                      {t(errors.price)}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="d-flex align-items-center justify-content-center mb-3">
                <img
                  onClick={handleChangeImage}
                  src={image ?? formValues.image ?? "./../images/item.png"}
                  className="itemIMG"
                  alt="Item img"
                />
                <input
                  onChange={handleImageChange}
                  ref={avatarRef}
                  type="file"
                  name="image"
                  accept="image/png,image/jpg,image/jpeg"
                  hidden
                />
              </div>
              {errors.image && (
                <span className="form-error">
                  <i
                    style={{ fontSize: ".8em" }}
                    className="fa fa-info-circle"
                  ></i>{" "}
                  {t(errors.image)}
                </span>
              )}
            </div>
          </div>
          <button
            onClick={__handleSubmit}
            type="submit"
            className="btn btn-primary my-3 mt-4 mx-auto col-12"
          >
            {t("Modifier")}
          </button>
        </form>
      </div>
    </>
  );
};

export default EditItemForm;
