import "./../../styles/TabPane.css";

const TabPane = ({
  activeKey,
  onSelect,
  label1 = (
    <>
      <i className="fa fa-credit-card" aria-hidden="true"></i> RACING CARTES
    </>
  ),
  label2 = (
    <>
      <i className="fa fa-shopping-cart"></i> MES COMMANDES
    </>
  ),
  label3 = (
    <>
      <i className="fas fa-hand-holding-usd me-1"></i> MES DONS
    </>
  ),
  child1,
  child2,
  child3,
}) => {
  return (
    <div className="TabPane mb-3">
      <div className="TabPane__menu d-flex mb-3">
        <div
          onClick={() => onSelect(0)}
          className={
            "TabPane__menu__item col justify-content-center " +
            (activeKey === 0 ? "active" : "")
          }
        >
          {label1}
        </div>
        <div
          onClick={() => onSelect(1)}
          className={
            "TabPane__menu__item col justify-content-center " +
            (activeKey === 1 ? "active" : "")
          }
        >
          {label2}
        </div>
        {/* <div
          onClick={() => onSelect(2)}
          className={
            'TabPane__menu__item col-4 justify-content-center ' +
            (activeKey === 2 ? 'active' : '')
          }
        >
          {label3}
        </div> */}
      </div>
      {activeKey === 0 && <div className="">{child1}</div>}
      {activeKey === 1 && <div className="">{child2}</div>}
      {/* {activeKey === 2 && <div className="">{child3}</div>} */}
    </div>
  );
};

export default TabPane;
