import React, { useRef, useEffect } from 'react';
import './../styles/Modal.css';

export function Modal({ open, title, backdrop, onClose, modalSize = '', children }) {
    onClose = onClose ?? null;
    const modalRef = useRef();
    const openModalRef = useRef();
    const closeModalRef = useRef();

    useEffect(() => {
        !open && closeModalRef.current.click()
        open && openModalRef.current.click()
        closeModalRef.current.addEventListener('click', () => {
            onClose()
        })

    }, [onClose, open]);

    return (
        <>
            {open &&
                <button ref={openModalRef} hidden type="button" data-bs-toggle="modal" data-bs-target="#DefaultModal"></button>
            }
            <div ref={modalRef} className="modal fade" data-bs-backdrop={backdrop ? "static" : "s"} data-bs-keyboard="false" id="DefaultModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className={"modal-dialog modal-dialog-centered " + modalSize}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
                            <button ref={closeModalRef} type="button" className={"btn-close " + (backdrop && onClose===null ? "d-none" : "")} data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        {children}
                    </div>
                </div>
            </div></>
    );

}
